import * as React from "react";
import Input from "components/Inputs/Input";
import Table from "components/Tablas/GenericaOrdenadaColumna";
import { now, dateString } from "utils/DateUtils";
import Button from 'components/atoms/Button';
import { consultarGastosEmailEntreFechas } from "network/Api/GastoEmail";

const ReporteGastos = () => {
  const [state, setDataState] = React.useState({
    feInicio: now()
    //   .subtract(1, "day")
      .format("YYYY-MM-01"),
    feFin: now().format("YYYY-MM-DD"),
    dsRemitente: "",
    gastos: [],
  });

  React.useEffect(() => consultarGastos(), []);

  const setState = (data) => setDataState((ds) => ({ ...ds, ...data }));

  const handleChange = ({ target }) =>
    setState({ [target.name]: target.value });

  const consultarGastos = () => {
    const { feInicio, feFin, dsRemitente } = state;
    consultarGastosEmailEntreFechas({ feInicio, feFin, dsRemitente }).then(
      ({ data }) => setState({ gastos: data })
    );
  };

  return (
    <>
      <div className="form-row col-xl-12">
        <div className="col-md-2 col-xs-2">
          <Input
            label="Fecha de inicio"
            name="feInicio"
            type="date"
            value={state.feInicio}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-2 col-xs-2">
          <Input
            label="Fecha de fin"
            name="feFin"
            type="date"
            value={state.feFin}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-2 col-xs-2">
          <Input
            label="Email Remitente"
            name="dsRemitente"
            value={state.dsRemitente}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-2 col-xs-2">
          <Button onClick={consultarGastos}>Consultar</Button>
        </div>
      </div>
      <Table
        headers={[
          { label: "Asunto", id: "dsAsunto" },
          { label: "Remitente", id: "dsRemitente" },
          { label: "Resultado", id: "dsEstado" },
          { label: "Estado", id: "cdEstado" },
          { label: "Fecha", id: "feProceso" },
        ]}
        keys={["dsAsunto", "dsRemitente", "dsEstado", "cdEstado", "feProceso"]}
        detalles={state.gastos.map(({ feProceso, ...rest }) => ({
          ...rest,
          feProceso: dateString(feProceso).format("DD/MM/YYYY HH:mm:ss"),
        }))}
        loading={false}
      />
    </>
  );
};

export default ReporteGastos;
