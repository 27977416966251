import * as React from "react";

const InputFile = ({ label, inputText, styleContainer, tooltip, ...rest }) => {
  return (
    <div style={styleContainer}>
      <label className="text-muted">{label}</label>
      <div className="custom-file">
        <input className="custom-file-input" type="file" {...rest} />
        <label className="custom-file-label">{inputText}</label>
      </div>
      {tooltip && <label>{tooltip}</label>}
    </div>
  );
};

export default InputFile;
