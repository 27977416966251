import * as React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button';
import Input from 'components/Inputs/Input';
import ButtonDescargaExcel from './ButtonDescargaExcel';

const FiltroConsultas = ({ handleChange, consultarGastos, state }) => {
  const { feInicio, feFin, prefijo, numero, nombreEmisor, cufe, gastos } = state;
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} style={{ margin: '3px' }}>
        <Input
          label='Fecha inicio'
          type='date'
          name='feInicio'
          value={feInicio}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={4} style={{ margin: '3px' }}>
        <Input label='Fecha fin' type='date' name='feFin' value={feFin} onChange={handleChange} />
      </Grid>
      <Grid item xs={3} style={{ margin: '3px' }}>
        <Input label='Prefijo' name='prefijo' value={prefijo} onChange={handleChange} />
      </Grid>
      <Grid item xs={5} style={{ margin: '3px' }}>
        <Input label='Num. documento' name='numero' value={numero} onChange={handleChange} />
      </Grid>
      <Grid item xs={5} style={{ margin: '3px' }}>
        <Input label='CUFE' name='cufe' value={cufe} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} style={{ margin: '3px' }}>
        <Input
          label='Nombre emisor'
          name='nombreEmisor'
          value={nombreEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid style={{ display: 'flex', alignItems: 'flex-end', margin: '3px' }} item xs={5}>
        <Button onClick={consultarGastos}>Consultar</Button>
      </Grid>
      <Grid style={{ display: 'flex', alignItems: 'flex-end', margin: '3px' }} item xs={4}>
        <ButtonDescargaExcel gastos={gastos} />
      </Grid>
    </Grid>
  );
};

export default FiltroConsultas;
