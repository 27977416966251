import * as React from "react";
import SelectEmisor from "components/Inputs/SelectConsultaEmisores";
import SelectPlanes, { Types } from "components/Inputs/InputPlanes";
import Button from 'components/atoms/Button';
import { asociarTiquetera as asociarTiqueteraEmisor, consultarTiqueterasPorEmisor } from "network/Api/Tiquetera";
import Table from "components/Tablas/GenericaOrdenadaColumna";
import Input from "components/Inputs/Input";
import Modal from "components/modal/ModalConfirmacion";

const AsociarTiquetera = () => {
  const [state, setData] = React.useState({});

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  React.useEffect(() => consultarTiquetera(), [state.emisor]);

  const consultarTiquetera = () => {
    const { emisor } = state;
    if (emisor) consultarTiqueterasPorEmisor({ emisor }).then(({ data }) => setState({ tiqueteras: data }));
  };

  const asociarTiquetera = () => {
    const errors = validarFormulario();
    if (errors.length === 0)
      asociarTiqueteraEmisor(getBody()).then((res) => {
        if (res.data) {
          openModal({ content: "Tiquetera asociada correctamente" });
          consultarTiquetera();
        }
      });
    else openModal({ content: errors.join(" - ") });
  };

  const getBody = () => {
    const { emisor, plan, nmInicio, nmFin } = state;
    return { emisor: { nmId: emisor }, plan: { nmId: plan }, nmInicio, nmFin };
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const validarFormulario = () => {
    const errores = [];
    const addError = (error) => errores.push(error);
    const { emisor, plan, nmInicio, nmFin } = state;
    if (!emisor) addError("El emisor es obligatorio");
    if (!plan) addError("El plan es obligatorio");
    if (!nmInicio) addError("El serial de inicio es obligatorio");
    if (!nmFin) addError("El serial de fin es obligatorio");
    return errores;
  };

  const openModal = ({ content, onCancel = () => setState({ openModal: false }) }) => setState({ openModal: true, content, onCancel });

  return (
    <div>
      <div>
        <div className="form-row">
          <div className="form-group col-xl-12">
            <SelectEmisor label="Selecciona un emisor *" handleConsultarEmisor={({ id }) => setState({ emisor: id })} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-xl-4">
            <SelectPlanes
              tipo={7}
              label="Selecciona un plan *"
              distribuidor={"PORTALFACTURA"}
              consulta={Types.consultarPorDistribuidorYTipo}
              handleChange={({ value }) => setState({ plan: value })}
            />
          </div>
          <div className="form-group col-xl-4">
            <Input label="Serial Inicio *" name="nmInicio" value={state.nmInicio} onChange={handleChange} />
          </div>
          <div className="form-group col-xl-4">
            <Input label="Serial Fin *" name="nmFin" value={state.nmFin} onChange={handleChange} />
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <Button onClick={asociarTiquetera}>Asociar Tiquetera</Button>
        </div>
      </div>
      <div>
        <Table
          headers={[{ id: "nmInicio", label: "Serial Inicio" }, { id: "nmFin", label: "Serial Fin" }, { id: "cdEstado", label: "Estado" }]}
          keys={["nmInicio", "nmFin", "cdEstado"]}
          detalles={(state.tiqueteras || []).map((tiquetera) => ({
            ...tiquetera,
            cdEstado: tiquetera.cdEstado === "A" ? "Activo" : "Inactivo",
          }))}
          loading={false}
        />
      </div>
      <Modal open={state.openModal} content={state.content} onCancel={state.onCancel} />
    </div>
  );
};

export default AsociarTiquetera;
