import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Table, TableBody, Paper } from "@material-ui/core";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import Button from 'components/atoms/Button';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 1,
    overflowX: "scroll",
  },
  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: "#128BCC",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  headText: {
    color: theme.palette.common.white,
  },
  bodyTotales: {
    fontWeight: "bold",
    backgroundColor: "#128BCC",
    color: theme.palette.common.white,
  },
  bodyDetalles: {
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  buttonAdd: {
    backgroundColor: "#92C63E",
    color: theme.palette.common.white,
  },
});

const FacturasReferenciaTable = ({
  facturasReferencia = [],
  classes,
  tipoDocumento,
  setDocumento,
}) => {
  const deleteReferencia = (id) => {
    setDocumento({
      facturasReferencia: facturasReferencia.filter((_, index) => index !== id),
    });
  };

  return facturasReferencia.length > 0 ? (
    <React.Fragment>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.headText}>Borrar</TableCell>
              <TableCell className={classes.headText}>Prefijo</TableCell>
              <TableCell className={classes.headText}># Documento</TableCell>
              <TableCell className={classes.headText}>
                Concepto nota{" "}
                {tipoDocumento === "NOTA_CREDITO" ? "débito" : "credito"}
              </TableCell>
              <TableCell className={classes.headText}>Descripcion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {facturasReferencia.map((row, index) => (
              <TableRow className={classes.bodyDetalles} key={index}>
                <TableCell>
                  <Button
                    onClick={() => deleteReferencia(index)}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
                <TableCell>{row.prefijo}</TableCell>
                <TableCell>{row.numero}</TableCell>
                <TableCell>
                  {row.conceptoNotaDebito || row.conceptoNotaCredito}
                </TableCell>
                <TableCell>{row.descripcion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default withStyles(styles)(FacturasReferenciaTable);
