import * as React from 'react';
import { Paper } from '@material-ui/core';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import Button from 'components/atoms/Button';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Create from '@material-ui/icons/Update';
import Update from '@material-ui/icons/Create';
import { activarDesactivarAdquirente } from 'network/Api/Adquirente';

const TablaAdquirentes = ({ adquirentes, filter, onConsulta, onOpenUpdate }) => {
  const changeEstado = async (nmId) => {
    const { data } = await activarDesactivarAdquirente({ nmId });
    if (data) onConsulta(filter);
  };

  return (
    <Paper>
      <Table
        loading={false}
        headers={[
          { id: 'btnActions', label: '' },
          { id: 'cdTipoIdentificacion', label: 'Tipo identificación' },
          { id: 'dsIdentificacion', label: 'No. Identificación' },
          { id: 'dsDigitoVerif', label: 'Dígito verificación' },
          { id: 'dsNombre', label: 'Nombre' },
          { id: 'dsPrimerApellido', label: 'Apellido' },
          { id: 'estado', label: 'Estado' },
        ]}
        keys={[
          'btnActions',
          'cdTipoIdentificacion',
          'dsIdentificacion',
          'dsDigitoVerif',
          'dsNombre',
          'dsPrimerApellido',
          'estado',
        ]}
        detalles={adquirentes.map((adquirente) => ({
          ...adquirente,
          estado: adquirente.cdEstado === 'A' ? 'Activo' : 'Inactivo',
          btnActions: (
            <>
              <Button variant='flat' onClick={() => changeEstado(adquirente.nmId)}>
                {adquirente.cdEstado === 'A' ? <DeleteForever /> : <Create />}
              </Button>
              {adquirente.cdEstado === 'A' && (
                <Button variant='flat' onClick={() => onOpenUpdate(adquirente)}>
                  <Update />
                </Button>
              )}
            </>
          ),
        }))}
      />
    </Paper>
  );
};

export default TablaAdquirentes;
