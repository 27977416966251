import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import Devengados from "./Devengados";
import Deducciones from "./Deducciones";
import Empleado from "./Empleado";
import SelectEmpleados, {
  getEmpleadosInput,
} from "components/Inputs/SelectEmpleados";
import { consultarEmpleadoPorId } from "network/Api/Empleado";

const EMPLEADO_NULL = { label: "Ninguno", value: null };
const EMPLEADO_CARGANDO = { label: "Cargando...", value: null };

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`Tab${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}

class Nomina extends Component {
  state = {
    tabSelected: 0,
    empleado: EMPLEADO_NULL,
    empleados: [],
    reload: true,
    empleadoJson: null,
  };

  componentDidUpdate() {
    this.consultarEmpleados();
  }

  consultarEmpleados = async () => {
    const { userAttributes } = this.props;
    const { reload } = this.state;
    if (reload && userAttributes.codigoEmisor) {
      this.setState(
        {
          empleado: EMPLEADO_CARGANDO,
          reload: false,
        },
        () =>
          getEmpleadosInput(userAttributes.codigoEmisor).then((empleados) =>
            this.setState({
              empleado: EMPLEADO_NULL,
              empleados,
              reload: false,
              empleadoJson: null,
            })
          )
      );
    }
  };

  resetEmpleado = () =>
    this.setState({
      empleado: EMPLEADO_NULL,
      reload: true,
      empleadoJson: null,
    });

  handleChangeTab = (_, tab) => this.setState({ tabSelected: tab });

  handleChangeEmpleado = async (event) => {
    const response = await consultarEmpleadoPorId({ nmId: event.value });
    this.setState({ empleado: event, empleadoJson: response.data });
  };

  render() {
    const { tabSelected, empleado, empleados, empleadoJson } = this.state;
    const { userAttributes } = this.props;
    return (
      <div>
        {userAttributes.codigoEmisor && (
          <div>
            <div className="form-row col-md-12">
              <div className="form-group col-xl-6">
                <SelectEmpleados
                  label="Seleccione un empleado"
                  empleados={empleados}
                  onChange={this.handleChangeEmpleado}
                  value={empleado}
                />
              </div>
            </div>
            <Paper>
              <AppBar position="static">
                <Tabs value={tabSelected} onChange={this.handleChangeTab}>
                  <Tab label="Empleados" id="Tab0" />
                  <Tab label="Devengados" id="Tab1" />
                  <Tab label="Deducciones" id="Tab2" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabSelected} index={0}>
                <Empleado
                  emisor={userAttributes.codigoEmisor}
                  empleado={empleadoJson}
                  resetEmpleado={this.resetEmpleado}
                />
              </TabPanel>
              <TabPanel value={tabSelected} index={1}>
                <Devengados
                  emisor={{ nmId: userAttributes.codigoEmisor }}
                  empleado={empleadoJson}
                />
              </TabPanel>
              <TabPanel value={tabSelected} index={2}>
                <Deducciones
                  emisor={{ nmId: userAttributes.codigoEmisor }}
                  empleado={empleadoJson}
                />
              </TabPanel>
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, _) {
  return { userAttributes: state.factura.userAttributes };
}

function mapDispatchToProps(_) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nomina);
