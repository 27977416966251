import * as React from 'react';
import './index.css';
import { consultarParametrosInicio } from 'network/Api/Parametro';

const Parrafo = ({ isLoged, classStyle }) => {
  const [index, setIndex] = React.useState(0);
  const [parrafos, setParrafos] = React.useState([]);
  const [delay, setDelay] = React.useState(10000);

  React.useEffect(() => {
    consultarParametrosInicio({ logged: isLoged }).then(({ data }) => {
      const { frases = [], intervalo = 10000 } = data;
      setDelay(intervalo);
      setParrafos(frases);
    });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % parrafos.length);
    }, delay);
    return () => clearInterval(interval);
  }, [parrafos.length]);

  return <div className={classStyle}>{parrafos[index]}</div>;
};

export default Parrafo;
