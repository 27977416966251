import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React from "react";

class TablaEncabezado extends React.Component {
  state = {
    rows: [
      {
        id: "dsIdentificacion",
        numeric: true,
        disablePadding: true,
        label: "Identificación emisor",
      },
      {
        id: "dsNombre",
        numeric: true,
        disablePadding: true,
        label: "Nombre emisor",
      },
      {
        id: "dsCelular",
        numeric: true,
        disablePadding: true,
        label: "Celular",
      },
      {
        id: "feProceso",
        numeric: true,
        disablePadding: false,
        label: "Fecha compra",
      },
      {
        id: "idPlan",
        numeric: true,
        disablePadding: false,
        label: "Plan comprado",
      },
      {
        id: "dsValorTransacion",
        numeric: true,
        disablePadding: false,
        label: "Valor transacción",
      },
      { id: "dsAliado", numeric: true, disablePadding: false, label: "Aliado" },
      {
        id: "cdEstadoTransacion",
        numeric: true,
        disablePadding: false,
        label: "Estado transacción",
      },
    ],
  };

  componentDidUpdate() {
    const { userId } = this.props;
    const { rows, currentUserId } = this.state;
    if (userId === "SERVICIOCLIENTE" && userId !== currentUserId) {
      this.setState({
        currentUserId: userId,
        rows: [
          ...rows,
          {
            id: "actualizarEstado",
            numeric: false,
            disablePadding: false,
            label: "Actualizar Estado",
          },
        ],
      });
    }
  }

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { rows } = this.state;
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? "center" : "center"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Ordenar"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

TablaEncabezado.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default TablaEncabezado;
