import * as React from "react";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { now } from "utils/DateUtils";
import DatosDocumento from "components/Documento/DatosDocumento";
import DatosAdquirente from "components/Documento/DatosCliente";
import DatosAdicionalesDocumento from "components/Documento/DatosAdicionalesDocumento";
import DetallesDocumento from "components/Documento/DetallesDocumento";
import { styles } from "./../DocumentoExcel/styles";
import ModalAddDetalle from "components/Documento/ModalAddDetalleRegistroDoc";
import ButtonLimpiar from "./ButtonLimpiar";
import ButtonEnviar from "./ButtonEnviar";
import Modal from "components/modal/ModalConfirmacion";

const DocumentoSoporteAdquisicion = ({ userAttributes, classes }) => {
  const [modalState, setModalState] = React.useState({});
  const [messageState, setDataMessageState] = React.useState({});
  const [documento, setDataDocumento] = React.useState({
    tipoDocumentoElectronico: "",
    moneda: "COP",
    fechaEmision: now().format("YYYY-MM-DD"),
    fechaVencimiento: now()
      .add(1, "month")
      .format("YYYY-MM-DD"),
    pago: {
      formaPago: "CONTADO",
      medioPago: "OTRO",
    },
    snDistribucionFisica: "N",
    VendedorResponsable: false,
    tipoPersonaVendedor: "NATURAL",
    tipoOperacion: "RESIDENTE",
    estandarProducto: "ESTANDAR_CONTRIBUYENTE",
  });

  React.useEffect(() => {
    const { VendedorResponsable } = documento;
    setDocumento({
      identificadorTributarioVendedor: VendedorResponsable ? "IVA" : "NO_APLICA",
    });
  }, [documento.VendedorResponsable]);

  React.useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    setDocumento({
      tipoDocumentoElectronico: url.get("tipo"),
      tipoIdentificacionVendedor: "NIT",
    });
  }, []);

  const setDocumento = (doc) => setDataDocumento((d) => ({ ...d, ...doc }));

  const setMessageState = (msg) => setDataMessageState((d) => ({ ...d, ...msg }));

  const handleCloseModal = () => setModalState((m) => ({ ...m, open: false }));

  const handleOpeneModal = () => setModalState((m) => ({ ...m, open: true }));

  const addDetalle = (detalle) => setDocumento({ detalles: [...(documento.detalles || []), detalle] });

  const openModal = ({ content, onAccept, onCancel = () => setMessageState({ open: false }), fullWidth = false }) =>
    setMessageState({ open: true, content, onAccept, onCancel, fullWidth });

  const limpiarFormulario = () => window.location.reload();

  return (
    <form>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <DatosDocumento
            setDocumento={setDocumento}
            documento={documento}
            emisor={userAttributes.codigoEmisor}
            showConsecutivo={true}
            isDocumentoSoporte={true}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Paper className={classes.paper}>
            <DatosAdquirente
              emisor={userAttributes.codigoEmisor}
              setDocumento={setDocumento}
              documento={documento}
              isDocumentoSoporte={true}
              guardarInformacion={true}
            />
          </Paper>
        </Grid>
        <Grid item md={4} xs={12}>
          <Paper className={classes.paper}>
            <DatosAdicionalesDocumento documento={documento} setDocumento={setDocumento} isDocumentoSoporte={true} />
          </Paper>
        </Grid>
        <Grid item md={12} xs={12}>
          <DetallesDocumento
            emisor={userAttributes.codigoEmisor}
            detalles={documento.detalles}
            setDocumento={setDocumento}
            addDetalles={handleOpeneModal}
            documento={documento}
            showDiaIva={false}
            isSoporte={true}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <div className="form-row col-md-12 d-flex justify-content-end pt-3">
            <div className="form-group col-xs-6">
              <ButtonLimpiar openModal={openModal} limpiarFormulario={limpiarFormulario} />
            </div>
            <div className="form-group col-xs-6">
              <ButtonEnviar documento={documento} openModal={openModal} limpiarFormulario={limpiarFormulario} userAttributes={userAttributes} />
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal
        open={messageState.open}
        content={messageState.content}
        onAccept={messageState.onAccept}
        onCancel={messageState.onCancel}
        fullWidth={messageState.fullWidth}
        maxWidth="xl"
      />
      <ModalAddDetalle
        emisor={userAttributes.codigoEmisor}
        addDetalle={addDetalle}
        modalState={modalState}
        handleClose={handleCloseModal}
        guardarProducto={true}
        isSoporte={true}
        addDescuento={false}
      />
    </form>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

export default connect(mapStateToProps)(withStyles(styles)(DocumentoSoporteAdquisicion));
