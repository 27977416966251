/* eslint-disable */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import EnviarFactura from '@material-ui/icons/AssignmentTurnedIn';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Logout,
  SetConfirmacionModalState,
  SetLoadingValue,
  SetSpinnerModal,
} from 'reducers/actions/facturaActions';
import ConfirmacionModal from 'components/modals/confirmacionModal';
import DatosAdicionalesDocumento from './datosAdicionalesDocumento';
import DatosCliente from './datosCliente';
import DatosDocumento from './datosDocumento';
import ListItems from './detalleFactura';
import moment from 'moment';
import { convertirALetras } from 'utils/valorEnLetras';
import './styles.css';
import { enviarDocumento } from 'network/Api/Documento';
import DatosSalud from './DatosSalud';
import { consultarSaldoEmisor } from 'network/Api/Saldo';
import { registrarRecarga } from 'network/Api/Recarga';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ButtonPrevisaulizacion from 'components/templates/PrevisualizacionDocumento/ButtonModal';
import Button from 'components/atoms/Button';

function getHours() {
  var date = new Date();
  var hora =
    (date.getHours().toString().length == 2
      ? date.getHours().toString()
      : '0' + date.getHours().toString()) +
    ':' +
    ((parseInt(date.getMinutes() / 5) * 5).toString().length == 2
      ? (parseInt(date.getMinutes() / 5) * 5).toString()
      : '0' + (parseInt(date.getMinutes() / 5) * 5).toString()) +
    ':00';
  return hora;
}

const styles = (theme) => ({
  root: {
    flexGrow: 2,
    maxWidth: '100%',
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.primary,
    boxShadow: '0px 0px 9px -6px rgba(0,0,0,0.75)',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      padding: 0,
    },
  },
  containerLogos: {
    flex: 1,
    display: 'inline-flex',
    [theme.breakpoints.down('md')]: {
      maxHeight: '3em',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    maxHeight: '6em',
    margin: 0,
    padding: 0,
  },
  rootLogo: {
    display: 'inline',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    maxHeight: 'inherit',
  },
  logo: {
    maxHeight: 'inherit',
  },
  logoPortal: {
    maxWidth: '35%',
    marginTop: '-15px',
  },
  fontFamily: {
    fontFamily: 'Quicksand',
  },
  show: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  showContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  lastBtnContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

let modal = {};

class GenerarFactura extends Component {
  state = {
    camposPendientes: [],
    finalObjeto: {},
  };

  componentWillMount() {
    this.props.setInitialState();
  }

  handleClickLimpiar = (event) => {
    event.preventDefault();
    this.props.setConfirmacionModalState({
      open: true,
      text: `¿Limpiar todos los campos?`,
      onClick: () => window.location.reload(),
    });
  };

  handleClickEnviar = (event) => {
    event.preventDefault();
    modal = {};
    if (!this.validarSaldo()) {
      modal = {
        open: true,
        text: `No tienes saldo para enviar facturas electrónicas. Lo invitamos a recargar.`,
        onClick: () => {
          this.props.setConfirmacionModalState({ open: false });
        },
      };
      this.props.setConfirmacionModalState(modal);
      return;
    }

    this.setState({ camposPendientes: [] }, () => {
      const { setConfirmacionModalState } = this.props;
      let result = this.validarCamposObligatorios();
      if (result) {
        this.validarSaldoEmisor().then((isEnvioInvalido) => {
          if (isEnvioInvalido) {
            setConfirmacionModalState({
              open: true,
              text: '¿Comprar un documento?',
              onClick: this.comprarDocumento,
            });
          } else this.finalizarEnvio();
        });
      } else {
        modal = {
          open: true,
          text: `¡Faltan campos por ingresar o se ingresaron de manera incorrecta! ${this.state.camposPendientes.map(
            (item) => ` - ${item}`,
          )}`,
          onClick: () => {
            this.props.setConfirmacionModalState({ open: false });
          },
        };
        this.props.setConfirmacionModalState(modal);
      }
    });
  };

  finalizarEnvio = () => {
    const { setConfirmacionModalState } = this.props;
    const finalObjeto = this.armarObjetoFinal();
    this.setState({ finalObjeto: finalObjeto }, () => {
      setConfirmacionModalState({
        open: true,
        text: `¿Enviar documento?`,
        onClick: this.handleClickConfirmarEnvio,
      });
    });
  };

  comprarDocumento = () => {
    consultarParametroPorNombre({ parametro: 'PLAN_RECARGA_UNO' }).then((plan) => {
      const { userAttributes, setConfirmacionModalState } = this.props;
      const { codigoEmisor } = userAttributes;
      registrarRecarga({
        emisor: { nmId: codigoEmisor },
        plan: { nmId: plan.data },
        cdCanal: 'PARALELA',
      }).then(({ data, status }) => {
        if (status === 200)
          setConfirmacionModalState({
            open: true,
            ...(data
              ? {
                  text: 'Compra realizada correctamente',
                  onClick: this.finalizarEnvio,
                }
              : { text: 'Saldo insuficiente' }),
          });
      });
    });
  };

  validarSaldoEmisor = async () => {
    const { userAttributes } = this.props;
    const { codigoEmisor } = userAttributes;
    const { data } = await consultarSaldoEmisor({ emisor: codigoEmisor });
    const documentosFaltantes = await consultarParametroPorNombre({
      parametro: 'DOCUMENTOS_FALTANTES_RECARGA',
    });
    return data <= documentosFaltantes.data;
  };

  handleClickConfirmarEnvio = () => {
    enviarDocumento(this.state.finalObjeto);
    modal = {
      open: true,
      text: `Su documento será procesado por nuestro sistema y podrá consultar su estado por la opción Documentos Emitidos de nuestro menú.`,
      onClick: () => {
        this.props.setConfirmacionModalState({ open: false });
        window.location.reload();
      },
      cancelarVisible: true,
    };
    this.props.setConfirmacionModalState(modal);
  };

  armarObjetoFinal = () => {
    const {
      datosDocumento,
      datosCliente,
      datosAdicionalesDocumento,
      listaDetalleFactura,
      userAttributes,
      attributesSuperEmisor,
      totalesFactura,
      listaDocumentosAdicionales,
      listaFacturasReferencia,
      isFacturaExportacion,
    } = this.props;
    let newListaDetalleFactura = [];
    let finalObjeto = {};
    let totalCopago = 0,
      totalModeradora = 0;
    listaDetalleFactura.map((item) => {
      newListaDetalleFactura.push({
        numeroFactura: datosDocumento.dsNumeroFactura,
        mandanteTipoIngreso: datosAdicionalesDocumento.mandanteTipoIngreso,
        mandanteIdentificacion: datosAdicionalesDocumento.mandanteIdentificacion,
        mandanteDigitoVerificacion: datosAdicionalesDocumento.mandanteDigitoVerificacion,
        mandanteNombre: datosAdicionalesDocumento.mandanteNombre,
        mandanteApellido: datosAdicionalesDocumento.mandanteApellido,
        mandanteTipoIdentifacion: datosAdicionalesDocumento.mandanteTipoIdentifacion,
        nota: datosAdicionalesDocumento.nota,
        ...item,
      });
      item.cargosDescuentos
        .filter((cargoDescuento) => cargoDescuento.razon === 'CUOTA_COPAGO')
        .forEach((copago) => (totalCopago += parseInt(copago.valor)));
      item.cargosDescuentos
        .filter((cargoDescuento) => cargoDescuento.razon === 'CUOTA_MODERADORA')
        .forEach((moderadora) => (totalModeradora += parseInt(moderadora.valor)));
    });

    let email = datosCliente.emailAdquiriente;
    datosCliente.finalObjeto.emailAdquiriente = email;

    let pago = {
      formaPago: datosAdicionalesDocumento.formaPago,
      medioPago: datosAdicionalesDocumento.medioPago,
      fechaVencimientoPago: `${datosAdicionalesDocumento.fechaVencimiento} ${getHours()}`,
    };

    let versionDian = 'VERSION_2';
    if (userAttributes.cdVersion == 1) {
      versionDian = 'VERSION_1';
    }

    //let valorBaseImponible = totalesFactura.valorBaseImponible

    if (totalesFactura.impuestosRetenciones.length === 0) {
      totalesFactura.valorBaseImponible = '0.00';
    }

    //delete totalesFactura.valorBaseImponible;
    if (datosDocumento.tipoDocumentoElectronico === 'NOTA_CREDITO') {
      datosCliente.finalObjeto.tipoOperacion =
        listaFacturasReferencia.length < 1
          ? 'NOTA_CREDITO_SINREFERENCIA'
          : 'NOTA_CREDITO_REFERENCIA';
    }

    if (datosDocumento.tipoDocumentoElectronico === 'NOTA_DEBITO') {
      datosCliente.finalObjeto.tipoOperacion =
        listaFacturasReferencia.length < 1 ? 'NOTA_DEBITO_SINREFERENCIA' : 'NOTA_DEBITO_REFERENCIA';
    }

    let incoterms = new Object();
    let tipoCambio = new Object();

    if (isFacturaExportacion || this.props.moneda !== 'COP') {
      incoterms = {
        condicionesEntrega: datosAdicionalesDocumento.condicionesEntrega,
        codigoIncoterm: datosAdicionalesDocumento.codigoIncoterm,
      };

      tipoCambio = {
        valorTipoCambio: datosAdicionalesDocumento.valorTipoCambio,
        fechaTipoCambio: `${datosAdicionalesDocumento.fechaTipoCambio} 00:00:00`,
      };
    }
    let dsDescuento = totalesFactura.descuentoTotal - totalCopago - totalModeradora;
    let finalDate = moment().add(142, 'second').format('YYYY-MM-DD HH:mm:ss');
    let totalPagar = totalesFactura.valorNeto;
    totalesFactura.impuestosRetenciones.forEach((ir) => {
      let tributo = ir.subtotales[0].tributo;
      if (tributo === 'RETE_ICA' || tributo === 'RETE_FUENTE' || tributo === 'RETE_IVA')
        totalPagar -= ir.valorTotal;
    });
    const valorNetoLetras =
      datosDocumento.tipoDocumentoElectronico === 'EXPORTACION' &&
      datosAdicionalesDocumento.otraMoneda
        ? convertirALetras(
            datosAdicionalesDocumento.valorNetoOtraMoneda,
            datosAdicionalesDocumento.otraMoneda,
          )
        : convertirALetras(totalPagar, datosAdicionalesDocumento.moneda);
    finalObjeto = {
      saldoEmisor: userAttributes.saldoEmisor,
      codigoEmisor: userAttributes.codigoEmisor,
      piePagina: userAttributes.piePagina,
      superEmisor: attributesSuperEmisor.superEmisor,
      codigoSuperEmisor: attributesSuperEmisor.codigoEmisor,
      versionDian: versionDian,
      identificacionEmisor: userAttributes.identificacionEmisor,
      detalles: newListaDetalleFactura,
      snDistribucionFisica: 'N',
      pago: pago,
      ...datosDocumento,
      ...datosAdicionalesDocumento,
      ...datosCliente.finalObjeto,
      ...totalesFactura,
      facturasReferencia: listaFacturasReferencia,
      documentosAdicionales: listaDocumentosAdicionales,
      anticipoTotal: '0',
      incoterms: incoterms,
      tipoCambio: tipoCambio,
      totalCopago: totalCopago,
      totalModeradora: totalModeradora,
      dsDescuento: dsDescuento > 0 ? dsDescuento : 0,
      feExpedicion: finalDate,
      totalPagar,
      valorNetoLetras,
      tipoOperacion: datosAdicionalesDocumento.tipoOperacion,
    };
    finalObjeto.resolucionDian = finalObjeto.dsResolucionDian;
    finalObjeto.ordenCompra = datosAdicionalesDocumento.ordenCompraPrefijo
      ? { numero: datosAdicionalesDocumento.ordenCompraPrefijo }
      : null;
    finalObjeto.detalles = finalObjeto.detalles.map((d) => {
      if (d.estandarProducto === '') d.estandarProducto = null;
      return d;
    });
    finalObjeto.incoterms = finalObjeto.incoterms.codigoIncoterm ? finalObjeto.incoterms : null;
    finalObjeto.facturasReferencia = finalObjeto.facturasReferencia.map((ff) => {
      switch (ff.tipo) {
        case 'NOTA_CREDITO':
          return { ...ff, conceptoNotaDebito: null };
        case 'NOTA_DEBITO':
          return { ...ff, conceptoNotaCredito: null };
        default:
          return ff;
      }
    });
    finalObjeto.dsPrefijo = finalObjeto.dsPrefijo ? finalObjeto.dsPrefijo : '';
    finalObjeto.msUsuarios = this.state.isDocumentoSalud ? this.state.finalObjeto.msUsuarios : null;
    finalObjeto.valorIvaOtraMoneda =
      totalesFactura.valorIva / datosAdicionalesDocumento.valorCambioTrm;
    finalObjeto.valorIncOtraMoneda =
      totalesFactura.valorTotalImpuestoConsumo / datosAdicionalesDocumento.valorCambioTrm;
    finalObjeto.descuentoTotalOtraMoneda =
      totalesFactura.descuentoTotal / datosAdicionalesDocumento.valorCambioTrm;
    return finalObjeto;
  };

  validarSaldo = () => {
    const { userAttributes } = this.props;
    if (userAttributes.saldoEmisor > 0) {
      return true;
    } else {
      return false;
    }
  };

  validarCamposObligatorios = () => {
    const {
      datosDocumento,
      datosCliente,
      datosAdicionalesDocumento,
      listaDetalleFactura,
      totalesFactura,
      isFacturaExportacion,
    } = this.props;

    let sw = true;

    if (Object.keys(datosCliente).length === 0) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push('Debe ingresar los datos del cliente'),
      });
      return sw;
    }

    if (Object.keys(datosDocumento).length === 0) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push('Debe ingresar los datos del documento'),
      });
      return sw;
    }

    if (Object.keys(datosAdicionalesDocumento).length === 0) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push('Debe ingresar los datos del documento'),
      });
      return sw;
    }

    if (listaDetalleFactura.length === 0) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push(
          'Ingrese al menos un item al detalle de la factura',
        ),
      });
      return sw;
    }

    if (totalesFactura.valorNeto <= 0) {
      if (listaDetalleFactura.filter((ld) => ld.obsequio) === 0) {
        sw = false;
        this.setState({
          camposPendientes: this.state.camposPendientes.push(
            'El total de la factura no puede ser menor a cero.',
          ),
        });
      }
      return sw;
    }

    if (
      (datosDocumento.tipoDocumentoElectronico !== 'VENTA' &&
        datosDocumento.tipoDocumentoElectronico !== 'EXPORTACION' &&
        datosDocumento.dsNumeroFactura === '') ||
      (datosDocumento.dsNumeroFactura === '' &&
        (datosDocumento.tipoDocumentoElectronico === 'VENTA' ||
          datosDocumento.tipoDocumentoElectronico === 'EXPORTACION') &&
        datosDocumento.snConsecutivoAutomatico === 'N')
    ) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push('Nro. Documento'),
      });
      return sw;
    }

    //Validacion datos encabezadoDocumento
    if (datosDocumento.disableControl) {
      for (let [key, value] of Object.entries(datosDocumento)) {
        if (
          key !== 'dsPrefijo' &&
          key !== 'dsPrefijoReferencia' &&
          key !== 'dsNumeroReferencia' &&
          key !== 'disableControl' &&
          key !== 'listaPrefijos' &&
          key !== 'unicoPrefijo' &&
          key !== 'numeroInicial' &&
          key !== 'numeroFinal' &&
          key !== 'prefijoDisable' &&
          key !== 'openDocumentoAdicionalModal' &&
          key !== 'dsNumeroFactura'
        ) {
          if (
            datosDocumento.dsNumeroFactura === '' &&
            datosDocumento.snConsecutivoAutomatico === 'N'
          )
            if (value === '') {
              sw = false;
              this.setState({
                camposPendientes: this.state.camposPendientes.push(key),
              });
            }
        }
      }
    } else {
      for (let [key, value] of Object.entries(datosDocumento)) {
        if (
          key !== 'dsPrefijo' &&
          key !== 'dsPrefijoReferencia' &&
          key !== 'dsNumeroReferencia' &&
          key !== 'disableControl' &&
          key !== 'listaPrefijos' &&
          key !== 'unicoPrefijo' &&
          key !== 'numeroInicial' &&
          key !== 'numeroFinal' &&
          key !== 'prefijoDisable' &&
          key !== 'openDocumentoAdicionalModal' &&
          key !== 'dsNumeroFactura'
        ) {
          if (value === '') {
            sw = false;
            this.setState({
              camposPendientes: this.state.camposPendientes.push(key),
            });
          }
        }
      }
    }

    //Validacion datosCliente
    if (datosCliente.disableControl) {
      for (let [key, value] of Object.entries(datosCliente.finalObjeto)) {
        if (
          key != 'segundoNombre' &&
          key != 'primerApellido' &&
          key != 'segundoApellido' &&
          key != 'ciudadAquiriente' &&
          key != 'codigoPostalAdquirente' &&
          key != 'adquirenteResponsable' &&
          key != 'multiValue' &&
          key != 'digitoVerificacionAdquirente' &&
          key != 'telefonoAdquiriente' &&
          key != 'direccionAdquiriente'
        ) {
          if (value == '') {
            sw = false;
            this.setState({
              camposPendientes: this.state.camposPendientes.push(key),
            });
          }
        }
      }
    } else {
      for (let [key, value] of Object.entries(datosCliente.finalObjeto)) {
        if (
          key != 'ciudadAquiriente' &&
          key != 'segundoNombre' &&
          key != 'segundoApellido' &&
          key != 'codigoPostalAdquirente' &&
          key != 'adquirenteResponsable' &&
          key != 'multiValue' &&
          key != 'digitoVerificacionAdquirente'
        ) {
          if (value == '') {
            sw = false;
            this.setState({
              camposPendientes: this.state.camposPendientes.push(key),
            });
          }
        }
      }
    }

    //validar digito verificacion no obligatorio para exportacion
    if (
      datosCliente.finalObjeto.tipoIdentificacionAdquiriente == 'REGISTRO_CIVIL' ||
      datosCliente.finalObjeto.tipoIdentificacionAdquiriente == 'TARJETA_IDENTIDAD' ||
      datosCliente.finalObjeto.tipoIdentificacionAdquiriente == 'CEDULA_CIUDADANIA'
    ) {
      if (datosCliente.finalObjeto.digitoVerificacionAdquirente == '') {
        sw = false;
        this.setState({
          camposPendientes: this.state.camposPendientes.push('digitoVerificacionAdquirente'),
        });
      }
    }

    if (Object.keys(datosCliente.finalObjeto.tipoIdentificacionAdquiriente).length === 0) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push('tipoIdentificacionAdquiriente'),
      });
    }

    if (datosCliente.formControlIsError) {
      sw = false;
      this.setState({
        camposPendientes: this.state.camposPendientes.push('e-mail'),
      });
    }

    //Validacion datos adicionales documento
    for (let [key, value] of Object.entries(datosAdicionalesDocumento)) {
      if (
        key != 'numeroCaracteresObservacion' &&
        key != 'dsObservacion' &&
        key != 'codigoIncoterm' &&
        key != 'condicionesEntrega' &&
        key != 'valorTipoCambio' &&
        key != 'fechaTipoCambio' &&
        key !== 'ordenCompraPrefijo' &&
        key !== 'mandanteTipoIngreso' &&
        key !== 'isFacturaExportacion' &&
        key !== 'mandanteDigitoVerificacion'
      ) {
        if (value == '') {
          sw = false;
          this.setState({
            camposPendientes: this.state.camposPendientes.push(key),
          });
        }
      }
    }

    if (isFacturaExportacion) {
      for (let [key, value] of Object.entries(datosAdicionalesDocumento)) {
        if (
          key !== 'dsObservacion' &&
          key !== 'fechaEmisionFull' &&
          key !== 'fechaVencimiento' &&
          key !== 'moneda' &&
          key !== 'numeroCaracteresObservacion' &&
          key !== 'formaPago' &&
          key !== 'mandanteTipoIngreso' &&
          key !== 'medioPago' &&
          key !== 'ordenCompraPrefijo' &&
          key !== 'mandanteDigitoVerificacion' &&
          key !== 'valorTipoCambio'
        ) {
          if (value == '') {
            sw = false;
            this.setState({
              camposPendientes: this.state.camposPendientes.push(key),
            });
          }
        }
      }
    }
    return sw;
  };

  addUsuariosSalud = (usuario) => {
    const { finalObjeto } = this.state;
    const { listaDetalleFactura } = this.props;
    const msCopago = listaDetalleFactura
      .filter(({ cargosDescuentos }) => cargosDescuentos)
      .map(({ cargosDescuentos }) => cargosDescuentos)
      .flat()
      .filter(({ razon }) => razon === 'CUOTA_COPAGO')
      .reduce((accum, { valor }) => accum + parseFloat(valor), 0);
    const msCuotaModeradora = listaDetalleFactura
      .filter(({ cargosDescuentos }) => cargosDescuentos)
      .map(({ cargosDescuentos }) => cargosDescuentos)
      .flat()
      .filter(({ razon }) => razon === 'CUOTA_MODERADORA')
      .reduce((accum, { valor }) => accum + parseFloat(valor), 0);
    this.setState({
      finalObjeto: {
        ...finalObjeto,
        msUsuarios: [{ ...usuario, msCopago, msCuotaModeradora }],
      },
    });
  };

  removeUsuarioSalud = () => {
    const { finalObjeto } = this.state;
    this.setState({ finalObjeto: { ...finalObjeto, msUsuarios: null } });
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  addConfigUsuarioSalud(usuario) {
    this.setState({ usuario });
  }

  handleChangeSalud(checked) {
    const { usuario } = this.state;
    const { setConfirmacionModalState } = this.props;
    if (usuario.msTipoUsuario) {
      this.setState({ isDocumentoSalud: checked });
    } else {
      this.setState({ isDocumentoSalud: false });
      this.removeUsuarioSalud();
      setConfirmacionModalState({
        text: 'Selecciona un adquirente con datos de salud',
        open: true,
        onClick: () => setConfirmacionModalState({ open: false }),
      });
    }
  }

  generarDocumentoPrevisaulizacion() {
    const { datosCliente, totalesFactura } = this.props;
    if (datosCliente.finalObjeto && totalesFactura.impuestosRetenciones) {
      return this.armarObjetoFinal();
    }
  }

  render() {
    const { classes, loading, userAttributes } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <Nav tabs className='spacing-head' role='button'>
            <NavItem className='col-sm-6 col-lg-4 col-xl'>
              <NavLink
                className={this.state.activeTab === '1' ? 'active' : ''}
                onClick={() => this.toggleTab('1')}
              >
                Información cliente
              </NavLink>
            </NavItem>
            <NavItem className='col-sm-6 col-lg-4 col-xl'>
              <NavLink
                className={this.state.activeTab === '2' ? 'active' : ''}
                onClick={() => this.toggleTab('2')}
              >
                Información documento
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId='1'>
              <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                  <DatosDocumento />
                  <Paper className={classes.paper} style={{ marginTop: '30px' }}>
                    <DatosCliente
                      addConfigUsuarioSalud={(usuario) => this.addConfigUsuarioSalud(usuario)}
                    />
                    <Button disabled={loading} onClick={() => this.toggleTab('2')}>
                      Siguiente
                    </Button>
                  </Paper>
                </Paper>
              </Grid>
            </TabPane>

            <TabPane tabId='2'>
              <Grid item xs={12} md={12} style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                  <ListItems />
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: '30px' }}>
                  <Paper className={classes.paper}>
                    <DatosAdicionalesDocumento />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: '30px' }}>
                  <Paper className={classes.paper}>
                    <DatosSalud
                      setMsUsuarios={this.addUsuariosSalud}
                      onChangeDocumentoSalud={(checked) => this.handleChangeSalud(checked)}
                      isDocumentoSalud={this.state.isDocumentoSalud}
                      infoUsuarioAdquirente={this.state.usuario}
                      removeUsuarioSalud={() => this.removeUsuarioSalud()}
                      openModal={(props) =>
                        this.props.setConfirmacionModalState({
                          ...props,
                          open: true,
                          onClick: () => this.props.setConfirmacionModalState({ open: false }),
                        })
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className='form-row'
                    style={{ marginTop: '30px', width: '100%', justifyContent: 'center' }}
                  >
                    <div className='form-group col-xs-4'>
                      <div className='form-label-group'>
                        <Button disabled={loading} onClick={() => this.toggleTab('1')}>
                          Atras
                        </Button>
                      </div>
                    </div>
                    <div className='form-group col-xs-4'>
                      <div className='form-label-group'>
                        <button
                          className='btn btn-md buttonEnviar'
                          onClick={this.handleClickLimpiar}
                        >
                          <DeleteIcon /> Limpiar
                        </button>
                      </div>
                    </div>

                    <div className='form-group col-xs-4'>
                      <div className='form-label-group'>
                        <button
                          className='btn btn-md buttonEnviar'
                          type='submit'
                          onClick={this.handleClickEnviar}
                          disabled={loading}
                        >
                          {loading && (
                            <span
                              className='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                          <EnviarFactura /> ENVIAR
                        </button>
                      </div>
                    </div>
                    <div className='form-group col-xs-12'>
                      <div className='form-label-group'>
                        <ButtonPrevisaulizacion
                          emisor={userAttributes}
                          documento={this.generarDocumentoPrevisaulizacion()}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
          </TabContent>
        </div>
        <ConfirmacionModal />
      </div>
    );
  }
}

GenerarFactura.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    loading: state.factura.loading,
    datosDocumento: state.factura.datosDocumento,
    datosCliente: state.factura.datosCliente,
    datosAdicionalesDocumento: state.factura.datosAdicionalesDocumento,
    listaDetalleFactura: state.factura.listaDetalleFactura,
    userAttributes: state.factura.userAttributes,
    attributesSuperEmisor: state.factura.attributesSuperEmisor,
    totalesFactura: state.factura.totalesFactura,
    listaFacturasReferencia: state.factura.listaFacturasReferencia,
    listaDocumentosAdicionales: state.factura.listaDocumentosAdicionales,
    isFacturaExportacion: state.factura.isFacturaExportacion,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setLoadingValue: (item) => dispatch(SetLoadingValue(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setInitialState: () => dispatch(Logout()),
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GenerarFactura));
