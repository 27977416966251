import React from "react";
import Tooltip from "components/atoms/Tooltip";
import { TIPOS_DATOS, validarString } from "utils/ValidarDatos";

const style = {
  inputError: {
    border: "2px solid red",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
};

const tiposEspeciales = {
  number: { max: true, min: true },
  email: {},
  decimal: {},
};

export default function input({
  label,
  margin,
  width = "100%",
  type = "text",
  autoComplete = "off",
  className = "form-control",
  tooltip,
  error,
  classNameComplete = "",
  onChange,
  value = "",
  onBlur,
  max,
  min,
  classLabel = "",
  ...rest
}) {
  const handleChange = (event) => {
    if (onChange) {
      const { value, dataset } = event.target;
      const { validation } = dataset;
      if (
        validation === "only-num-text" &&
        value !== "" &&
        !/^[A-Za-z0-9\s À-ÿ\u00f1\u00d1]+$/.test(value)
      )
        return;
      if (
        validation === "only-num-decimals" &&
        value !== "" &&
        !/^[0-9.]+$/.test(value)
      )
        return;
      if (
        validation === "only-num" &&
        value !== "" &&
        !/^[0-9]+$/.test(value)
      )
        return;
      if (
        validation === "only-num-simbol" &&
        value !== "" &&
        !/^[0-9!-@#$%^&*()\-=_+]+$/.test(value)
      )
        return;
      switch (type) {
        case "number":
          handleChangeNumber({ value, event });
          break;
        default:
          onChange(event);
          break;
      }
    }
  };

  const handleChangeNumber = ({ value, event }) => {
    if (validarString(value, TIPOS_DATOS.numeros) || value === "") {
      if ((value >= parseInt(min) && value <= parseInt(max)) || value === "")
        onChange(event);
      if ((value >= parseInt(min) && !max) || value === "") onChange(event);
    }
  };

  return (
    <div style={{ margin, width }} className={classNameComplete}>
      {label && <label className={classLabel}>{label}</label>}
      <div style={style.container}>
        <input
          style={error ? style.inputError : {}}
          className={className}
          autoComplete={autoComplete}
          type={tiposEspeciales[type] ? "text" : type}
          onChange={handleChange}
          value={value}
          max={max}
          min={min}
          onBlur={onBlur}
          {...rest}
        />
        <Tooltip text={tooltip} />
      </div>
      {error && <small style={{ color: "red" }}>{error}</small>}
    </div>
  );
}
