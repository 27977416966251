import * as React from 'react';
import { Paper } from '@material-ui/core';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import Button from 'components/atoms/Button';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Create from '@material-ui/icons/Update';
import Update from '@material-ui/icons/Create';
import { actualizarEstadoProducto } from 'network/Api/Producto';
const { useState } = React;

const TablaProductos = ({ productos, filter, onConsulta, onOpenUpdate }) => {
  const [loading, setLoading] = useState(false);

  const changeEstado = async (nmId, cdEstado) => {
    setLoading(true);
    const { data } = await actualizarEstadoProducto({ nmId, cdEstado });
    if (data) onConsulta(filter);
    setLoading(false);
  };

  return (
    <Paper>
      <Table
        loading={false}
        headers={[
          { id: 'btnActions', label: '' },
          { id: 'dsCodigo', label: 'Código' },
          { id: 'dsDescripcion', label: 'Descripción' },
          { id: 'nmValorUnitario', label: 'Valor unitario' },
          { id: 'nmPorcentajeIva', label: 'Porcentaje IVA' },
          { id: 'nmPorcentajeInc', label: 'Porcentaje INC' },
          { id: 'nmUnidadMedida', label: 'Unidad medida' },
          { id: 'estado', label: 'Estado' },
        ]}
        keys={[
          'btnActions',
          'dsCodigo',
          'dsDescripcion',
          'nmValorUnitario',
          'nmPorcentajeIva',
          'nmPorcentajeInc',
          'nmUnidadMedida',
          'estado',
        ]}
        detalles={productos.map((producto) => ({
          ...producto,
          estado: producto.cdEstado === 'A' ? 'Activo' : 'Inactivo',
          btnActions: (
            <>
              <Button
                variant='text'
                disabled={loading}
                onClick={() => changeEstado(producto.nmId, producto.cdEstado === 'A' ? 'I' : 'A')}
              >
                {producto.cdEstado === 'A' ? <DeleteForever /> : <Create />}
              </Button>
              {producto.cdEstado === 'A' && (
                <Button variant='text' disabled={loading} onClick={() => onOpenUpdate(producto)}>
                  <Update />
                </Button>
              )}
            </>
          ),
        }))}
      />
    </Paper>
  );
};

export default TablaProductos;
