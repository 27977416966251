import React from 'react';
import ARadio from 'components/atoms/Radio';

function Radio({ label, customDivStyle = {}, ...rest }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', ...customDivStyle }}>
      {label}
      <ARadio {...rest} />
    </div>
  );
}

export default Radio;
