import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

const styles = () => ({
  tableWrapper: { overflow: "auto" },
  footerTabla: { backgroundColor: "#3C3588", color: "white" },
});

const defaultState = {
  page: 0,
  rowsPerPage: 10,
};

function OrdenadaColumna(props) {
  const {
    headers = [],
    orderBy,
    order,
    detalles = [],
    keys = [],
    classes,
    withPagination = true,
    loading = true,
    classnames = {},
    showTable = true,
    ...rest
  } = props;
  const [state, setState] = useState({ ...defaultState, ...props });

  function reverseOrder(property) {
    const orderBy = property;
    let order = "desc";
    if (state.orderBy === property && state.order === "desc") {
      order = "asc";
    }
    setState((s) => ({ ...s, order, orderBy }));
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    else if (b[orderBy] > a[orderBy]) return 1;
    else return 0;
  }

  function stableSort(array, callback) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = callback(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getDetallesPagination() {
    if (withPagination) {
      return stableSort(detalles, getSorting(state.order, state.orderBy)).slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      );
    } else {
      return stableSort(detalles, getSorting(state.order, state.orderBy));
    }
  }
  return (
    <>
      <div className={classes.tableWrapper} style={{ display: showTable ? "block" : "none" }}>
        <Table {...rest}>
          <TableHead className={classnames.headerRow}>
            <TableRow>
              {headers.map(
                ({ id, numeric, label, disablePadding = false }, key) => (
                  <TableCell
                    key={"header" + key}
                    align={"center"}
                    padding={disablePadding ? "none" : "default"}
                    sortDirection={state.orderBy === id ? state.order : false}
                    className={classnames.titleHeader}
                  >
                    {label && (
                      <Tooltip
                        title="Ordenar"
                        placement={numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={state.orderBy === id}
                          direction={state.order}
                          onClick={() => reverseOrder(id)}
                        >
                          {label}
                        </TableSortLabel>
                      </Tooltip>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {!loading ? (
            detalles.length ? (
              <TableBody>
                {getDetallesPagination().map((row, key) => (
                  <TableRow key={key} hover role="checkbox">
                    {keys.map((cell, cellKey) => (
                      <TableCell key={key + cellKey}>{row[cell]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <></>
            )
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={headers.length}
                  style={{ textAlign: "center" }}
                >
                  <div className="spinner-border text-primary" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </div>
      <TablePagination
        style={{ display: withPagination ? "block" : "none" }}
        className={classes.footerTabla}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={detalles.length}
        rowsPerPage={state.rowsPerPage}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Página anterior",
        }}
        nextIconButtonProps={{
          "aria-label": "Página siguiente",
        }}
        onChangePage={(_, page) => setState((s) => ({ ...s, page }))}
        onChangeRowsPerPage={(e) =>
          setState((s) => ({ ...s, rowsPerPage: e.target.value }))
        }
      />
    </>
  );
}

export default withStyles(styles)(OrdenadaColumna);
