import * as React from 'react';
import LabelTitulos from 'components/layout/labelTitulos';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import TextArea from 'components/Inputs/TextArea';
import tiposPago from 'static/enums/tiposPago';
import tiposMediosPago from 'static/enums/tiposMediosPago';
import tiposMonedas from 'static/enums/tiposMonedas';
import { now } from 'utils/DateUtils';
import DatosExportacion from './DatosExportacion';
import SelectEstandarProducto from './SelectEstandarProducto';

const DatosAdicionalesDocumento = ({ setDocumento, documento, isDocumentoSoporte }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    switch (name) {
      case 'dsObservacion':
        setDocumento({ [name]: value.replace('<', '').replace('>', '') });
        break;
      default:
        setDocumento({ [name]: value });
        break;
    }
  };

  const setPago = (data) => setDocumento({ pago: { ...documento.pago, ...data } });

  const setOrdenCompra = ({ target }) =>
    setDocumento({
      ordenCompra: target.value ? { numero: target.value } : null,
    });

  const getFormasPago = () => tiposPago.map((p) => ({ label: p.nombre, value: p.codigo }));

  const getMediosPago = () => {
    if (isDocumentoSoporte)
      return tiposMediosPago
        .filter((m) => m.soporte)
        .map((m) => ({ label: m.nombre, value: m.codigo }));
    return tiposMediosPago
      .filter((m) => m.documento)
      .map((m) => ({ label: m.nombre, value: m.codigo }));
  };

  const getMonedas = () => tiposMonedas.map((m) => ({ label: m.nombre, value: m.moneda }));

  return (
    <div className='form-row'>
      <div className='form-group col-md-12 mt-0 mb-0'>
        <LabelTitulos texto='Información documento' />
      </div>
      <div className='form-row col-md-12 d-flex justify-content-between'>
        <div className='form-group col-xl-6 col-sm-12'>
          <Select
            label='Forma de pago *'
            name='formaPago'
            options={getFormasPago()}
            onChange={(event) => setPago({ formaPago: event.value })}
            value={getFormasPago().filter((p) => p.value === (documento.pago || {}).formaPago)}
          />
        </div>
        <div className='form-group col-xl-6 col-sm-12'>
          <Select
            label='Moneda *'
            isDisabled={true}
            name='moneda'
            options={getMonedas()}
            value={getMonedas().filter((m) => m.value === documento.moneda)}
            onChange={(event) => setDocumento({ moneda: event.value })}
          />
        </div>
      </div>
      <div className='form-row col-md-12 d-flex justify-content-between'>
        <div className='form-group col-xl-12 col-sm-12'>
          <Select
            label='Medio de pago *'
            name='medioPago'
            options={getMediosPago()}
            onChange={(event) => setPago({ medioPago: event.value })}
            value={getMediosPago().filter((p) => p.value === (documento.pago || {}).medioPago)}
          />
        </div>
      </div>
      <div className='form-row col-md-12 d-flex justify-content-between'>
        <div className={`form-group col-xl-4`}>
          <Input
            type={'date'}
            label='Fecha documento'
            onChange={({ target }) => {
              const { value, name } = target;
              setDocumento({ [name]: value });
            }}
            value={documento.fechaEmision}
            name='fechaEmision'
            min={now().format('YYYY-MM-DD')}
            max={now().format('YYYY-MM-DD')}
          />
        </div>
        <div className={`form-group col-xl-4`}>
          <Input
            type={'date'}
            label='Fecha de vencimiento'
            onChange={({ target }) =>
              setDocumento({
                [target.name]: target.value,
              })
            }
            value={documento.fechaVencimiento}
            name='fechaVencimiento'
          />
        </div>
        {!isDocumentoSoporte ? (
          <div className='form-group col-xl-4'>
            <Input
              label='Orden de referencia'
              name='ordenCompra.numero'
              value={documento.ordenCompra && documento.ordenCompra.numero}
              onChange={setOrdenCompra}
            />
          </div>
        ) : (
          <div className='form-group col-xl-4'>
            <SelectEstandarProducto
              label='Estandar Producto'
              name='estandarProducto'
              value={documento.estandarProducto}
              onChange={({ value }) => setDocumento({ estandarProducto: value })}
            />
          </div>
        )}
      </div>
      <div className='form-row col-md-12'>
        <div className='form-group col-md-12 '>
          <TextArea
            label='Observación'
            style={{ height: '10.55rem' }}
            maxLength='300'
            rows='3'
            name='dsObservacion'
            value={documento.dsObservacion}
            onChange={handleChange}
            textMuted={`${documento.dsObservacion ? documento.dsObservacion.length : 0}/300 Caracteres`}
            sinSalto={true}
          />
        </div>
      </div>
      {documento.moneda !== 'COP' && (
        <DatosExportacion documento={documento} setDocumento={setDocumento} />
      )}
    </div>
  );
};

export default DatosAdicionalesDocumento;
