import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import LabelTitulos from 'components/layout/labelTitulos';

export default function modalConfirmacion({
  open = false,
  content = <div />,
  onAccept,
  onCancel,
  title = 'PortalFactura',
  fullWidth,
  maxWidth = 'sm',
  extraButtons = [],
  fullScreen,
}) {
  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={fullScreen}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <LabelTitulos tipo='encabezado' texto={title} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {onAccept && <Button onClick={onAccept}>Aceptar</Button>}
        {extraButtons.map((buttonOptions) => (
          <Button onClick={buttonOptions.onClick}>{buttonOptions.name}</Button>
        ))}
        {onCancel && <Button onClick={onCancel}>Cerrar</Button>}
      </DialogActions>
    </Dialog>
  );
}
