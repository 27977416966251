/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import LabelTitulos from "../layout/labelTitulos";
import { connect } from "react-redux";
import { SetDatosCliente } from "../../reducers/actions/facturaActions";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  handleKeyPressTextoNumeroGuion,
  handleKeyPressTexto,
  handleKeyPressTextoNumeros,
  handleKeyPressTextoDireccion,
  handleKeyPressTextoEmail,
  handleKeyPressNumeros,
} from "../../utils/funcionesUtiles";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Update from "@material-ui/icons/Create";
import { consultarAdquirentesPorEmisorYNombre } from "network/Api/Adquirente";
import Async from 'react-select/async';
import SelectCiudades from "components/Inputs/SelectCiudades";

let regEmail = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;

const styles = (theme) => ({
  root: { flexGrow: 2, maxWidth: "100%" },
  fontFamily: { whiteSpace: "nowrap", textAlign: "left" },
  show: {
    display: "block",
    [theme.breakpoints.down("md")]: { display: "none" },
  },
});

class DatosCliente extends Component {
  state = {
    tiposIdentificacion: [],
    disableControl: true,
    formControlIsError: false,
    Ciudades: [],
    tipoPersonaAdquiriente: "",
    tipoIdentificacionAdquiriente: {},
    identificacionAdquiriente: "",
    digitoVerificacionAdquirente: "",
    nombresAdquiriente: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    ciudadAdquiriente: {},
    direccionAdquiriente: "",
    telefonoAdquiriente: "",
    emailAdquiriente: "",
    codigoPostalAdquirente: "",
    regimenAdquirente: "",
    adquirenteResponsable: false,
    tipoOperacion: "ESTANDAR",
    responsabilidadesFiscales: "",
    multiValue: [],
    nombreCiudad: "",
    nombreRegimen: "",
    cambiarCiudad: false,
    finalObjeto: {
      tipoPersonaAdquiriente: "",
      tipoIdentificacionAdquiriente: {},
      identificacionAdquiriente: "",
      digitoVerificacionAdquirente: "",
      nombresAdquiriente: "",
      segundoNombre: "",
      primerApellido: "",
      segundoApellido: "",
      ciudadAdquiriente: {},
      direccionAdquiriente: "",
      telefonoAdquiriente: "",
      emailAdquiriente: "",
      codigoPostalAdquirente: "",
      regimenAdquirente: "",
      adquirenteResponsable: false,
      tipoOperacion: "ESTANDAR",
      responsabilidadesFiscales: "",
    },
  };

  filterAdquirente = (inputValue) => {
    return consultarAdquirentesPorEmisorYNombre({
      idEmisor: this.props.userAttributes.codigoEmisor,
      filtro: inputValue,
    }).then((res) => {
      this.setState({ adquirentes: res.data });
      return res.data.map((i) => ({
        value: i.nmId,
        label: `${i.cdTipoIdentificacion}: ${i.dsIdentificacion}-${
          i.dsDigitoVerif
        } ${i.dsNombre} ${i.dsSegundoNombre} ${i.dsPrimerApellido} ${
          i.dsSegundoApellido
        }`,
      }));
    });
  };

  loadOptions = (inputValue, callback) => {
    if (this.props.userAttributes.codigoEmisor) {
      this.filterAdquirente(inputValue).then((res) => callback(res));
    }
  };

  handleMultiChange = (option) => {
    let multiValue = this.state.multiValue;
    let isExistente = multiValue.filter((m) => m.value === option.value);
    let nuevasResponsabilidades =
      isExistente.length > 0
        ? multiValue.filter((m) => m.value !== option.value)
        : [...multiValue, option];
    let responsabilidadesString = nuevasResponsabilidades
      .map((nr) => nr.value)
      .join(";");
    this.setState(
      {
        multiValue: nuevasResponsabilidades,
        responsabilidadesFiscales: responsabilidadesString,
        finalObjeto: {
          ...this.state.finalObjeto,
          responsabilidadesFiscales: responsabilidadesString,
        },
      },
      () => this.props.dispatch(SetDatosCliente({ ...this.state }))
    );
  };

  componentDidMount() {
    this.setState(
      {
        tiposIdentificacion: this.props.tiposIdentificacion,
        finalObjeto: { ...this.state.finalObjeto },
      },
      () => {
        this.props.dispatch(SetDatosCliente({ ...this.state }));
      }
    );
  }

  handleChangeAdquirenteResponsable = (event) => {
    this.setState(
      {
        adquirenteResponsable: event.target.checked,
        finalObjeto: {
          ...this.state.finalObjeto,
          adquirenteResponsable: event.target.checked,
        },
      },
      () => {
        this.props.dispatch(SetDatosCliente({ ...this.state }));
      }
    );
  };

  handleChange = (event) => {
    let value = event.target.value;
    const {
      identificacionAdquiriente,
      tipoIdentificacionAdquiriente,
    } = this.state;
    if (
      event.target.id == "digitoVerificacionAdquirente" &&
      identificacionAdquiriente != "" &&
      tipoIdentificacionAdquiriente.value == "NIT"
    ) {
      let identificacion = identificacionAdquiriente.split("-", 2);
      let newIdentificacion = `${identificacion[0]}`;
      if (value != "") {
        newIdentificacion = `${identificacion[0]}-${value}`;
      }

      this.setState(
        {
          [event.target.id]: value,
          identificacionAdquiriente: newIdentificacion,
          finalObjeto: {
            ...this.state.finalObjeto,
            [event.target.id]: value,
            identificacionAdquiriente: newIdentificacion,
          },
        },
        () => {
          this.props.dispatch(SetDatosCliente({ ...this.state }));
        }
      );
      return;
    }
    this.setState(
      {
        [event.target.id]: value,
        finalObjeto: { ...this.state.finalObjeto, [event.target.id]: value },
      },
      () => {
        this.props.dispatch(SetDatosCliente({ ...this.state }));
      }
    );
  };

  handleKeyPressTexto = (event) => {
    if (!handleKeyPressTexto(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoNumeros = (event) => {
    if (!handleKeyPressTextoNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumeros = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoNumeroGuion = (event) => {
    if (!handleKeyPressTextoNumeroGuion(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoDireccion = (event) => {
    if (!handleKeyPressTextoDireccion(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoEmail = (event) => {
    if (!handleKeyPressTextoEmail(event)) {
      event.preventDefault();
    }
  };

  handleChangeEmail = (event) => {
    this.setState(
      {
        emailAdquiriente: event.target.value,
        formControlIsError: false,
        finalObjeto: {
          ...this.state.finalObjeto,
          emailAdquiriente: event.target.value,
        },
      },
      () => {
        const { emailAdquiriente } = this.state;
        let sw = false;
        emailAdquiriente.split(",").map((result) => {
          const email = result.match(regEmail) || [];
          if (email[0] !== result) {
            sw = true;
          }
        });
        if (sw) {
          this.setState({ formControlIsError: true }, () => {
            this.props.dispatch(SetDatosCliente({ ...this.state }));
          });
        } else {
          this.props.dispatch(SetDatosCliente({ ...this.state }));
        }
      }
    );
  };

  handleChangeCliente = async (selectedOption) => {
    let codigo = selectedOption.value;
    const { tiposResponsabilidadesFiscales } = this.props;
    const { adquirentes } = this.state;
    let adquirenteSeleccionado = adquirentes.filter(
      (item) => item.nmId == codigo
    );
    adquirenteSeleccionado = adquirenteSeleccionado[0];
    let seleccionados = adquirenteSeleccionado.nmResponFiscales.split(";");
    let arrayMultiValue = seleccionados.map((itemSeleccionados) => {
      if (itemSeleccionados === "") return;
      let result = tiposResponsabilidadesFiscales.filter(
        (item) => itemSeleccionados == item.codigo
      );
      return {
        value: result[0].codigo,
        label: result[0].nombre,
      };
    });

    let ciudad = adquirenteSeleccionado.ciudad;
    let nombreCiudad = `${ciudad.dsNombre}, ${ciudad.departamento.dsNombre}, ${
      ciudad.departamento.pais.dsNombre
    }`;
    this.setState(
      {
        tipoPersonaAdquiriente: adquirenteSeleccionado.cdTipoPersona,
        tipoIdentificacionAdquiriente:
          adquirenteSeleccionado.cdTipoIdentificacion,
        identificacionAdquiriente: adquirenteSeleccionado.dsIdentificacion,
        digitoVerificacionAdquirente: adquirenteSeleccionado.dsDigitoVerif,
        nombresAdquiriente: adquirenteSeleccionado.dsNombre,
        segundoNombre: adquirenteSeleccionado.dsSegundoNombre,
        primerApellido: adquirenteSeleccionado.dsPrimerApellido,
        segundoApellido: adquirenteSeleccionado.dsSegundoApellido,
        direccionAdquiriente: adquirenteSeleccionado.dsDireccion,
        ciudadAdquiriente: ciudad,
        telefonoAdquiriente: adquirenteSeleccionado.dsTelefono,
        emailAdquiriente: adquirenteSeleccionado.dsEmail,
        regimenAdquirente: adquirenteSeleccionado.cdTipoRegimen,
        // adquirenteResponsable:
        //   adquirenteSeleccionado.cdAdqResponsable === "1" ? true : false,
        responsabilidadesFiscales: adquirenteSeleccionado.nmResponFiscales,
        multiValue: arrayMultiValue,
        nombreCiudad: nombreCiudad,
        nombreRegimen: adquirenteSeleccionado.cdTipoRegimen,
        finalObjeto: {
          ...this.state.finalObjeto,
          tipoPersonaAdquiriente: adquirenteSeleccionado.cdTipoPersona,
          tipoIdentificacionAdquiriente:
            adquirenteSeleccionado.cdTipoIdentificacion,
          identificacionAdquiriente:
            adquirenteSeleccionado.cdTipoIdentificacion === "NIT"
              ? `${adquirenteSeleccionado.dsIdentificacion}-${
                  adquirenteSeleccionado.dsDigitoVerif
                }`
              : adquirenteSeleccionado.dsIdentificacion,
          digitoVerificacionAdquirente: adquirenteSeleccionado.dsDigitoVerif,
          nombresAdquiriente: adquirenteSeleccionado.dsNombre,
          segundoNombre: adquirenteSeleccionado.dsSegundoNombre,
          primerApellido: adquirenteSeleccionado.dsPrimerApellido,
          segundoApellido: adquirenteSeleccionado.dsSegundoApellido,
          ciudadAdquiriente: ciudad,
          direccionAdquiriente: adquirenteSeleccionado.dsDireccion,
          telefonoAdquiriente: adquirenteSeleccionado.dsTelefono,
          emailAdquiriente: adquirenteSeleccionado.dsEmail,
          codigoPostalAdquirente: "",
          regimenAdquirente: adquirenteSeleccionado.cdTipoRegimen,
          // adquirenteResponsable:
          //   adquirenteSeleccionado.cdAdqResponsable === "1" ? true : false,
          tipoOperacion: "ESTANDAR",
          responsabilidadesFiscales: adquirenteSeleccionado.nmResponFiscales,
        },
      },
      () => this.props.dispatch(SetDatosCliente({ ...this.state }))
    );
  };

  handleChangeCiudad = (selectedOption) => {
    this.setState({
      ciudadAdquiriente: { nmId: selectedOption.value },
    });
  };

  handleConsultaCliente = (newValue) => {
    return consultarAdquirentesPorEmisorYNombre({
      idEmisor: this.props.userAttributes.codigoEmisor,
      filtro: newValue,
    }).then((res) => {
      this.setState({ adquirentes: res.data });
      return newValue;
    });
  };

  render() {
    const {
      formControlIsError,
      identificacionAdquiriente,
      nombresAdquiriente,
      segundoNombre,
      primerApellido,
      segundoApellido,
      direccionAdquiriente,
      telefonoAdquiriente,
      emailAdquiriente,
      codigoPostalAdquirente,
      adquirenteResponsable,
      responsabilidadesFiscales,
      digitoVerificacionAdquirente,
      nombreCiudad,
      nombreRegimen,
      cambiarCiudad,
    } = this.state;
    const { tiposResponsabilidadesFiscales } = this.props;

    let tiposResponsabilidadesFiscalesSelect = [];
    tiposResponsabilidadesFiscales.map((item) => {
      tiposResponsabilidadesFiscalesSelect.push({
        value: item.codigo,
        label: item.nombre,
      });
    });

    return (
      <div className="form-row">
        <div className="form-group col-md-12 mt-0 mb-0">
          <LabelTitulos texto="Información cliente" />
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-md-12 col-xl-8">
            <label style={styles.fontFamily}>Cliente *</label>
            <Async
              defaultOptions={true}
              loadOptions={this.loadOptions}
              onInputChange={this.handleConsultaCliente}
              onChange={this.handleChangeCliente}
            />
          </div>

          <div className="form-group col-md-12 col-xl-2">
            <label style={styles.fontFamily}>Nro. Documento *</label>
            <div className="form-group">
              <input
                maxLength="35"
                autoComplete="off"
                type="text"
                id="identificacionAdquiriente"
                className="form-control form-control-md"
                value={identificacionAdquiriente}
                onKeyPress={this.handleKeyPressTextoNumeros}
                required
                disabled={true}
              />
            </div>
          </div>
          <div className="form-group col-md-12 col-xl-2">
            <label>Dígito Verif. *</label>
            <div className="form--group">
              <input
                maxLength="1"
                autoComplete="off"
                type="text"
                id="digitoVerificacionAdquirente"
                className="form-control form-control-md"
                value={digitoVerificacionAdquirente}
                onKeyPress={this.handleKeyPressTextoNumeros}
                required
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-md-3 mb-0">
            <label>Nombre o Razón Social*</label>
            <div className="form-group">
              <input
                maxLength="200"
                autoComplete="off"
                type="text"
                id="nombresAdquiriente"
                className="form-control form-control-md"
                value={nombresAdquiriente}
                onKeyPress={this.handleKeyPressTextoNumeros}
                disabled={true}
                required
              />
            </div>
          </div>
          <div className="form-group col-md-3 mb-0">
            <label>Segundo Nombre</label>
            <div className="form-group">
              <input
                maxLength="200"
                autoComplete="off"
                type="text"
                id="segundoNombre"
                className="form-control form-control-md"
                value={segundoNombre}
                onKeyPress={this.handleKeyPressTexto}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-group col-md-3 mb-0">
            <label>Primer Apellido*</label>
            <div className="form-group">
              <input
                maxLength="200"
                autoComplete="off"
                type="text"
                id="primerApellido"
                className="form-control form-control-md"
                value={primerApellido}
                onKeyPress={this.handleKeyPressTexto}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-group col-md-3 mb-0">
            <label>Segundo Apellido</label>
            <div className="form-group">
              <input
                maxLength="200"
                autoComplete="off"
                type="text"
                id="segundoApellido"
                className="form-control form-control-md"
                value={segundoApellido}
                onKeyPress={this.handleKeyPressTexto}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-md-12 col-xl-1 pl-5">
            <label style={{ color: "#fff" }}>Editar</label>
            {!cambiarCiudad && (
              <Update onClick={() => this.setState({ cambiarCiudad: true })} />
            )}

            {cambiarCiudad && (
              <DeleteForever
                onClick={() => this.setState({ cambiarCiudad: false })}
              />
            )}
          </div>

          {cambiarCiudad && (
            <div className="form-group col-md-12 col-xl-4">
              <label>Ciudad*</label>
              <SelectCiudades
                onChange={this.handleChangeCiudad}
                placeholder="Seleccióne una ciudad"
              />
            </div>
          )}
          {!cambiarCiudad && (
            <div className="form-group col-md-12 col-xl-4">
              <label>Ciudad*</label>
              <input
                maxLength="200"
                autoComplete="off"
                type="text"
                id="nombreCiudad"
                className="form-control form-control-md"
                value={nombreCiudad}
                disabled={true}
              />
            </div>
          )}
          {/* <div className="form-group col-xl-4 mb-0">
            <label>Dirección *</label>
            <div className="form-group">
              <input
                maxLength="100"
                autoComplete="off"
                type="text"
                id="direccionAdquiriente"
                className="form-control form-control-md"
                value={direccionAdquiriente}
                onChange={this.handleChange}
                required
              />
            </div>
          </div> */}
          {/* <div className="form-group col-xl-3 mb-0">
            <label>Télefono *</label>
            <div className="form-group">
              <input
                maxLength="255"
                autoComplete="off"
                type="text"
                id="telefonoAdquiriente"
                className="form-control form-control-md"
                value={telefonoAdquiriente}
                onChange={this.handleChange}
                required
              />
            </div>
          </div> */}
          <div className="form-group col-xl-4 mb-0">
            <label>Email*</label>
            <div className="form-group">
              <input
                maxLength="100"
                style={formControlIsError ? { border: "2px solid red" } : null}
                autoComplete="off"
                type="text"
                id="emailAdquiriente"
                className="form-control form-control-md"
                value={emailAdquiriente}
                onKeyPress={this.handleKeyPressTextoEmail}
                onChange={this.handleChangeEmail}
                required
              />
            </div>
          </div>
          <div className="form-group col-md-12 col-xl-3">
            <label>Código Postal</label>
            <div className="form-group">
              <input
                maxLength="10"
                autoComplete="off"
                type="text"
                id="codigoPostalAdquirente"
                className="form-control form-control-md"
                value={codigoPostalAdquirente}
                disabled={true}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-row col-md-12">
          {/* <div className="form-group col-md-12 col-xl-4">
            <label>Regimen Adquiriente</label>
            <input
              maxLength="200"
              autoComplete="off"
              type="text"
              id="nombreRegimen"
              className="form-control form-control-md"
              value={nombreRegimen}
              disabled={true}
            />
          </div> */}
        </div>
        <div className="form-row col-md-12 d-flex justify-content-between">
          {/* <div className="form-group col-md-3 col-xl-3 mt-2">
            <div class="custom-control custom-checkbox">
              <label>Seleccione si es:</label>
              <input
                checked={adquirenteResponsable}
                onChange={this.handleChangeAdquirenteResponsable.bind(this)}
                type="checkbox"
                class="custom-control-input"
                id="adquirenteResponsable"
              />
              <label class="custom-control-label" for="adquirenteResponsable">
                Adquirente responsable
              </label>
            </div>
          </div> */}
          <div className="form-group col-md-12 col-xl-5">
            <div>
              <label>Responsabilidades fiscales *</label>
              <Select
                name="responsabilidadesFiscales"
                placeholder="Responsabilidades fiscales"
                value={this.state.multiValue}
                options={tiposResponsabilidadesFiscalesSelect}
                onChange={this.handleMultiChange.bind(this)}
                multi
              />
            </div>
          </div>
          <div className="form-group col-md-12 col-xl-4">
            <label>Responsabilidades seleccionadas</label>
            <div className="form-label-group">
              <textarea
                style={{ height: "2.46rem" }}
                disabled={true}
                rows="3"
                autoComplete="off"
                type="text"
                id="responsabilidadesFiscales"
                className="form-control form-control-sm"
                value={responsabilidadesFiscales}
                placeholder="Responsabilidades fiscales"
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DatosCliente.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ciudadesArray: state.factura.ciudadesArray,
    tiposIdentificacion: state.factura.tiposIdentificacion,
    tiposPersona: state.factura.tiposPersona,
    regimenAdquirente: state.factura.regimenAdquirente,
    listaDetalleFactura: state.factura.listaDetalleFactura,
    tiposResponsabilidadesFiscales:
      state.factura.tiposResponsabilidadesFiscales,
    adquirentes: state.maestros.adquirentes,
    userAttributes: state.factura.userAttributes,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DatosCliente));
