export const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 1,
    overflowX: "scroll",
  },
  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: "#128BCC",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  headText: {
    color: theme.palette.common.white,
  },
  bodyTotales: {
    fontWeight: "bold",
    backgroundColor: "#128bcc",
    color: theme.palette.common.black,
  },
  bodyDetalles: {
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  buttonAdd: {
    backgroundColor: "#92C63E",
    color: theme.palette.common.white,
  },
});
