import { serviceRoutes, postFormData, get, post } from "./index";

export const registrarGasto = async (body) =>
  await postFormData(serviceRoutes.registrarGasto, body);

export const consultarGastos = async ({
  emisor,
  feInicio,
  feFin,
  prefijo = "",
  numero = "",
  nombreEmisor = "",
  cufe = "",
}) =>
  await get(
    serviceRoutes.consultarGastos,
    `?id=${emisor}&inicio=${feInicio}&fin=${feFin}&prefijo=${prefijo}&numero=${numero}&emisor=${nombreEmisor}&cufe=${cufe}`
  );

export const actualizarEventoGasto = async (body) =>
  await post(serviceRoutes.actualizarEventoGasto, body);
