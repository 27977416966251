import * as React from 'react';
import { Button } from '@material-ui/core';
import { exportTableToExcel } from 'utils/ExportarExcelUtil';
import ExportExcelIco from 'static/images/excel.png';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import { dateString } from 'utils/DateUtils';
const { useCallback, useMemo } = React;

const ButtonDescargaExcel = ({ gastos = [] }) => {
  const exportar = useCallback(() => exportTableToExcel('tablaExcel', 'gastosEnviados'), [gastos]);

  const detalles = useMemo(() => {
    return gastos.map((gasto) => ({
      ...gasto,
      feGeneracion: dateString(gasto.feGeneracion).format('DD/MM/YYYY HH:mm:ss'),
    }));
  }, [gastos]);

  return (
    <>
      <Button onClick={exportar}>
        <img alt='exportarExcel' src={ExportExcelIco} />
      </Button>
      <Table
        id='tablaExcel'
        orderBy={'feDocumento'}
        withPagination={false}
        showTable={false}
        loading={false}
        headers={[
          { label: 'Fecha Doc', id: 'feGeneracion' },
          { label: 'NIT Emisor', id: 'dsNitEmisor' },
          { label: 'Nombre Emisor', id: 'dsNombreEmisor' },
          { label: 'Prefijo', id: 'dsPrefijo' },
          { label: 'Numero', id: 'dsNumero' },
          { label: 'Estado Documento', id: 'cdEstado' },
          { label: 'CUFE', id: 'dsCufe' },
        ]}
        tableStyle={{ display: 'none' }}
        keys={[
          'feGeneracion',
          'dsNitEmisor',
          'dsNombreEmisor',
          'dsPrefijo',
          'dsNumero',
          'cdEstado',
          'dsCufe',
        ]}
        detalles={detalles}
      />
    </>
  );
};

export default ButtonDescargaExcel;
