/* eslint-disable */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { SetDetalleFactura } from "../../reducers/actions/facturaActions";
import {
  numberFormat,
  handleKeyPressDecimal,
  handleKeyPressNumeros,
} from "../../utils/funcionesUtiles";
import DetalleFacturaAgregar from "./DetalleFacturaAgregar";
import {
  SetConfirmacionModalState,
  SetModalAddDetalleFactura,
  SetTotalesFactura,
} from "../../reducers/actions/facturaActions";
import Add from "@material-ui/icons/AddCircle";
import {
  impuestosAdicionalesEncabezado,
  impuestosAdicionalesRetefuente,
  impuestosAdicionalesICA,
  impuestosAdicionalesReteIva,
} from "./Utiles/ImpuestosAdicionalesEncabezado";
import { cargosDescuentosAgrupar } from "./Utiles/CargosDescuentos";

import { convertirALetras } from "../../utils/valorEnLetras";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 1,
    overflowX: "scroll",
  },
  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: "#128BCC",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  headText: {
    color: theme.palette.common.white,
  },
  bodyTotales: {
    fontWeight: "bold",
    backgroundColor: "#128bcc",
    color: theme.palette.common.black,
  },
  bodyDetalles: {
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  buttonAdd: {
    backgroundColor: "#92C63E",
    color: theme.palette.common.white,
  },
});

const porcentajeReteIva = 15;
let valorIvaTemp = 0;

function ccyFormat(num) {
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return numberFormat(value);
}

function formatearDecimales(num) {
  if (num == "") {
    num = 0;
  }
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return value;
}

function calcularValores(items, tipo) {
  if (items.length > 0) {
    switch (tipo) {
      case "sub_total": {
        return items
          .map(({ valorBrutoItem }) => parseFloat(valorBrutoItem))
          .reduce((sum, i) => sum + i, 0);
      }
      case "total_iva": {
        return items
          .map(({ valorIva }) => parseFloat(valorIva))
          .reduce((sum, i) => sum + i, 0);
      }
      case "valor_no_gravado": {
        var valorNoGravado = 0;
        items.map((item) => {
          if (
            parseFloat(item.valorIva) == 0 &&
            parseFloat(item.impuestoConsumo) == 0
          ) {
            let valorDescuento = 0;
            item.cargosDescuentos.map((item) => {
              valorDescuento += parseFloat(item.valor);
            });
            valorNoGravado +=
              parseFloat(item.valorBrutoItem) - parseFloat(valorDescuento);
          }
        });
        return valorNoGravado;
      }
      case "total_ico": {
        return items
          .map(({ impuestoConsumo }) => parseFloat(impuestoConsumo))
          .reduce((sum, i) => sum + i, 0);
      }
      case "total_descuento": {
        return items
          .map(({ valorTotalDescuento }) => parseFloat(valorTotalDescuento))
          .reduce((sum, i) => sum + i, 0);
      }
      default:
        return 0;
    }
  }
  return 0;
}

class DetalleFactura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      idItemBorrar: 0,
      porcentajeRetefuente: 0,
      valorRetefuente: 0,
      valorICA: 0,
      aplicarReteIva: false,
      valorReteIva: 0,
      bolsas: "",
    };
    this.handleClickModal = this.handleClickModal.bind(this);
  }

  handleClickBorrar = (event) => {
    this.setState({ items: this.props.rows, idItemBorrar: event }, () => {
      let item = this.state.items.filter(function(item) {
        return item.id === event;
      });
      let modal = {
        open: true,
        text: `¿Borrar item ${item[0].codigoArticulo} ${
          item[0].descripcionArticulo
        } ?`,
        onClick: this.handleClickModal,
      };
      this.props.dispatch(SetConfirmacionModalState(modal));
    });
  };

  handleClickModal = () => {
    let { items, idItemBorrar } = this.state;
    let newItems = items.filter(function(item) {
      return item.id !== idItemBorrar;
    });
    this.props.dispatch(SetDetalleFactura(newItems));
    this.props.dispatch(SetConfirmacionModalState({ open: false }));
  };

  handleClickAdd = () => {
    const { datosDocumento } = this.props;
    if (Object.keys(datosDocumento).length > 0) {
      this.props.dispatch(SetModalAddDetalleFactura(true));
    }
  };

  handleChange = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) return;
    if (event.target.value < 0) event.target.value = 0;
    if (event.target.value > 100) event.target.value = 100;
    if (
      event.target.value.split(".")[1] &&
      event.target.value.split(".")[1].length > 2
    )
      return;
    this.setState({ porcentajeRetefuente: event.target.value });
  };

  handleChangeValorICA = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) return;
    if (event.target.value < 0) event.target.value = 0;
    if (event.target.value > 100) event.target.value = 100;
    if (
      event.target.value.split(".")[1] &&
      event.target.value.split(".")[1].length > 3
    )
      return;
    this.setState({ valorICA: event.target.value });
  };

  handleChangeReteIva = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) {
      return;
    }
    if (event.target.checked) {
      let valorIva = parseFloat(event.target.value);
      let valorReteIva = (valorIva * porcentajeReteIva) / 100;
      this.setState({
        valorReteIva: valorReteIva,
        aplicarReteIva: event.target.checked,
      });
    } else {
      this.setState({ valorReteIva: 0, aplicarReteIva: event.target.checked });
    }
  };

  handleChangeBolsas = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) {
      return;
    }
    if (event.target.value === "") {
      this.setState({ bolsas: 0 });
      return;
    }
    if (event.target.value < 0) event.target.value = 0;
    this.setState({ bolsas: event.target.value });
  };

  handleKeyPressDecimal = (event) => {
    if (!handleKeyPressDecimal(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumeros = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  calcularReteIva = (valor_iva) => {
    const { rows } = this.props;
    if (rows.length == 0) {
      if (this.state.aplicarReteIva == true) {
        this.setState({ valorReteIva: 0, aplicarReteIva: false });
      }
    } else {
      if (this.state.aplicarReteIva == true) {
        if (valorIvaTemp != valor_iva) {
          valorIvaTemp = valor_iva;
          let valorIva = parseFloat(valor_iva);
          let valorReteIva = (valorIva * porcentajeReteIva) / 100;
          this.setState({ valorReteIva: valorReteIva });
        }
      }
    }
  };

  render() {
    const { classes, rows, datosAdicionalesDocumento } = this.props;
    const {
      porcentajeRetefuente,
      valorICA,
      aplicarReteIva,
      valorReteIva,
      bolsas,
    } = this.state;
    const valorBruto = calcularValores(rows, "sub_total");
    const valorIva = calcularValores(rows, "total_iva");
    const valorIco = calcularValores(rows, "total_ico");
    const valorDescuento = calcularValores(rows, "total_descuento");
    const valorNoGravado = calcularValores(rows, "valor_no_gravado");
    this.calcularReteIva(valorIva);

    /*Se obtiene impuestos y retenciones IVA e ICO para encabezado validacion previa*/
    let impuestosRetenciones = [];
    let impuestosRetencionesObjecto = {};
    impuestosRetencionesObjecto = impuestosAdicionalesEncabezado(rows, "IVA");
    if (impuestosRetencionesObjecto != null) {
      impuestosRetenciones.push(impuestosRetencionesObjecto);
    }
    impuestosRetencionesObjecto = impuestosAdicionalesEncabezado(rows, "INC");
    if (impuestosRetencionesObjecto != null) {
      impuestosRetenciones.push(impuestosRetencionesObjecto);
    }
    /*Finaliza impuestos y retenciones*/

    let valorBaseImponible = valorBruto - valorDescuento - valorNoGravado;
    if (valorBaseImponible < 0) valorBaseImponible = 0;
    const valorParaRetencion = valorBruto - valorDescuento;
    const valorRetefuente = valorParaRetencion * (porcentajeRetefuente / 100);

    if (porcentajeRetefuente != 0) {
      impuestosRetenciones.push(
        impuestosAdicionalesRetefuente(
          valorParaRetencion,
          valorRetefuente,
          porcentajeRetefuente
        )
      );
    }

    if (valorICA != 0) {
      impuestosRetenciones.push(
        impuestosAdicionalesICA(valorBruto - valorDescuento, valorICA)
      );
    }

    if (valorReteIva != 0) {
      impuestosRetenciones.push(
        impuestosAdicionalesReteIva(valorIva, valorReteIva, porcentajeReteIva)
      );
    }

    let cargosDescuentosAgrupado = cargosDescuentosAgrupar(rows);

    // if (bolsas != '') {
    //   impuestosRetenciones.push(impuestosAdicionalesBolsa());
    // }

    //const valorTotal = ((valorBruto - valorDescuento) - valorRetefuente) + (valorIva - parseFloat(valorReteIva)) + valorIco - parseFloat(valorICA);
    const valorTotal = valorBruto - valorDescuento + valorIva + valorIco;

    let valorEnLetras = convertirALetras(
      valorTotal,
      datosAdicionalesDocumento.moneda
    );

    const valorBrutoMasTributos = valorBruto + valorIco + valorIva; //- (valorRetefuente + parseFloat(valorReteIva) + parseFloat(valorICA));
    valorBaseImponible = formatearDecimales(valorBaseImponible);
    if (rows.length > 0) {
      this.props.dispatch(
        SetTotalesFactura({
          valorNeto: formatearDecimales(valorTotal),
          valorBrutoMasTributos: formatearDecimales(valorBrutoMasTributos),
          porcentajeIva: 0,
          dsPorcentajeReteIca: rows[0].porcentajeIca,
          porcentajeConsumo: 0,
          valorBaseImponible: valorBaseImponible,
          valorTotalImpuestoConsumo: formatearDecimales(valorIco),
          dsRetencionIca: formatearDecimales(valorICA),
          valorBruto: formatearDecimales(valorBruto),
          valorIva: formatearDecimales(valorIva),
          valorOtrosImpuestos: 0,
          valorNoGravado: formatearDecimales(valorDescuento + valorNoGravado),
          valorNetoLetras: valorEnLetras,
          dsRetencionFuente: formatearDecimales(valorRetefuente),
          descuentoTotal: formatearDecimales(valorDescuento),
          cargoTotal: formatearDecimales(0),
          impuestosRetenciones: impuestosRetenciones,
          dsRetencionIva: formatearDecimales(valorReteIva),
          cargosDescuentos: cargosDescuentosAgrupado,
        })
      );
    }

    return (
      <Fragment>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.headText}>Código</TableCell>
                <TableCell className={classes.headText}>Descripción</TableCell>
                <TableCell className={classes.headText} align="right">
                  Cantidad
                </TableCell>
                <TableCell className={classes.headText} align="right">
                  Valor Unitario
                </TableCell>
                <TableCell className={classes.headText} align="right">
                  %IVA
                </TableCell>
                <TableCell className={classes.headText} align="right">
                  %INC
                </TableCell>
                <TableCell className={classes.headText} align="right">
                  Descuento
                </TableCell>
                <TableCell className={classes.headText} align="right">
                  Valor Total
                </TableCell>
                <TableCell className={classes.headText} align="right">
                  Dia sin IVA
                </TableCell>
                <TableCell className={classes.headText}>
                  <Button
                    className={classes.buttonAdd}
                    onClick={this.handleClickAdd}
                    aria-label="Agregar"
                  >
                    <Add />
                    Agregar
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  <TableRow className={classes.bodyDetalles} key={row.id}>
                    <TableCell>{row.codigoArticulo}</TableCell>
                    <TableCell>{row.descripcionArticulo}</TableCell>
                    <TableCell align="right">
                      {ccyFormat(row.cantidad)}
                    </TableCell>
                    <TableCell align="right">
                      ${ccyFormat(row.precioUnitario)}
                    </TableCell>
                    <TableCell align="right">{row.porcentajeIva}%</TableCell>
                    <TableCell align="right">
                      {row.porcentajeConsumo}%
                    </TableCell>
                    <TableCell align="right">
                      ${ccyFormat(row.valorTotalDescuento)}
                    </TableCell>
                    <TableCell align="right">
                      ${ccyFormat(row.valorTotalArticuloDetalle)}
                    </TableCell>
                    <TableCell align="right">
                      <input
                        type="checkbox"
                        checked={row.esSinIva}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          this.handleClickBorrar(row.id);
                        }}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={12} />
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Total Descuento
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  ${ccyFormat(valorDescuento)}
                </TableCell>
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Total bruto
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  ${ccyFormat(valorBruto)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Porcentaje Rete ICA
                </TableCell>

                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  <input
                    value={valorICA}
                    min="0"
                    max="100"
                    placeholder="%"
                    onKeyPress={this.handleKeyPressDecimal}
                    onChange={this.handleChangeValorICA}
                    id="valorICA"
                    className="form-control"
                  />
                </TableCell>
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Porcentaje Retefuente
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  <input
                    value={porcentajeRetefuente}
                    min="0"
                    max="100"
                    placeholder="%"
                    onKeyPress={this.handleKeyPressDecimal}
                    onChange={this.handleChange}
                    id="txtPorcentajeRetefuente"
                    className="form-control"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Rete Iva (15%)
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      checked={aplicarReteIva}
                      value={valorIva}
                      onChange={this.handleChangeReteIva}
                      type="checkbox"
                      class="custom-control-input check"
                      id="aplicarReteIva"
                      style={{ Color: "#92C63E" }}
                    />
                    <label class="custom-control-label" for="aplicarReteIva">
                      ${valorReteIva == "" ? "0.00" : ccyFormat(valorReteIva)}
                    </label>
                  </div>
                </TableCell>
                <TableCell className={classes.bodyTotales} colSpan={1} />
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  <input
                    style={{ display: "none" }}
                    value={bolsas}
                    type="number"
                    min="1"
                    max="100"
                    placeholder="Total bolsas"
                    onKeyPress={this.handleKeyPressNumeros}
                    onChange={this.handleChangeBolsas}
                    id="txtCantidadBolsas"
                    className="form-control"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Total IVA
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  ${ccyFormat(valorIva)}
                </TableCell>
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Total INC
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={2}
                  align="right"
                >
                  ${ccyFormat(valorIco)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={2}>
                  Total neto
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={4}
                  align="right"
                >
                  ${ccyFormat(valorTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={1}>
                  Total en letras
                </TableCell>
                <TableCell
                  className={classes.bodyTotales}
                  colSpan={5}
                  align="center"
                >
                  {valorEnLetras}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <DetalleFacturaAgregar />
      </Fragment>
    );
  }
}

DetalleFactura.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    rows: state.factura.listaDetalleFactura,
    datosDocumento: state.factura.datosDocumento,
    datosCliente: state.factura.datosCliente,
    datosAdicionalesDocumento: state.factura.datosAdicionalesDocumento,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DetalleFactura));
