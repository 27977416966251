import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Table, TableHead, TableBody, TableCell, TableRow, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Visibility, VisibilityOff, CheckRounded, ClearRounded, Info } from "@material-ui/icons/";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import { actualizarListaResoluciones, registrarResolucionesDomina, guardarNuevaResolucion } from "network/Api/Resolucion";
import { guardarNuevaResolucionSoporte, actualizarListaResolucionesSoporte } from "network/Api/ResolucionSoporte";
import { consultarConfiguracion } from "network/Api/Emisor";
import { getDateFromArray } from "utils/DateUtils";

class ResolcionTable extends Component {
  state = {
    datosParametros: null,
    modalOpen: false,
    msgModal: "",
  };

  actualizarResolucion = (body, tipo) => {
    const { handleConsultarResoluciones, emisor, setSpinnerModal } = this.props;
    setSpinnerModal(true);
    (tipo === "FACTURACION" ? actualizarListaResoluciones(body) : actualizarListaResolucionesSoporte(body)).then((_) => {
      handleConsultarResoluciones(emisor);
      setSpinnerModal(false);
    });
  };

  actualizarEstado = ({ tipo, cdEstado, nmId }) => this.actualizarResolucion([{ nmId, cdEstado: this.estadoConverter(cdEstado) }], tipo);

  actualizarConsecutivoAutomatico = ({ tipo, snConsecutivoAutomatico, nmId }) =>
    this.actualizarResolucion([{ nmId, snConsecutivoAutomatico: this.snConverter(snConsecutivoAutomatico) }], tipo);

  actualizarResolucionPrincipal = (resolucion) => {
    const { resoluciones } = this.props;
    const tipoOpe = resolucion.tipo;
    const principal = resoluciones.find(({ snPrincipal, tipo }) => snPrincipal === "S" && tipo === tipoOpe);
    this.actualizarResolucion(
      [principal && { nmId: principal.nmId, snPrincipal: "N" }, { nmId: resolucion.nmId, snPrincipal: "S" }].filter((r) => r),
      tipoOpe
    );
  };

  estadoConverter = (estado) => {
    switch (estado) {
      case "A":
        return "I";
      case "I":
        return "A";
      default:
        return "I";
    }
  };

  snConverter = (sn) => {
    switch (sn) {
      case "S":
        return "N";
      case "N":
        return "S";
      default:
        return "N";
    }
  };

  guardarResoluciones = async () => {
    const { emisor, setSpinnerModal } = this.props;
    setSpinnerModal(true);
    const emisorConfig = await consultarConfiguracion({ nmId: emisor });
    const resoluciDomina = await registrarResolucionesDomina({ id: emisor });
    if (!resoluciDomina.data.length) this.handleOpenModal("No hay nuevas resoluciones para configurar");
    this.setState({ emisorConfig, resoluciones: resoluciDomina.data }, () => this.guardarResolucionesPortal());
    setSpinnerModal(false);
  };

  guardarResolucionesPortal = () => {
    const { resoluciones = [] } = this.state;
    const resolucion = resoluciones[0];
    if (resolucion) {
      this.handleOpenModal(
        <div>
          <h5>Seleccione si la resolución es de facturación o de soporte:</h5>
          <br />
          <h6>
            <b>Resolucion:</b> {resolucion.dsResolucionDian}
          </h6>
          <h6>
            <b>Prefijo:</b> {resolucion.dsPrefijo}
          </h6>
          <h6>
            <b>Inicio:</b> {resolucion.nmInicio}
          </h6>
          <h6>
            <b>Fin:</b> {resolucion.nmFin}
          </h6>
          <br />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button onClick={() => this.handleSaveResolucion("FACTURACION", resolucion)} color="primary" variant="contained">
              Resolución de Facturación
            </Button>
            <Button onClick={() => this.handleSaveResolucion("SOPORTE", resolucion)} color="primary" variant="contained">
              Resolución de Soporte
            </Button>
          </div>
        </div>
      );
    } else {
      this.handleOpenModal(
        <div className="alert alert-primary">
          <Info /> Señor usuario, actualmente no cuenta con resoluciones asociadas en la Dian para configurar en PortalFactura.
        </div>
      );
    }
  };

  handleSaveResolucion = (tipo, resolucion) => {
    const { resoluciones = [] } = this.state;
    const { handleConsultarResoluciones, emisor } = this.props;
    const resolucionesFiltradas = this.filtrar(resoluciones, resolucion);
    const body = { ...resolucion, emisor: { nmId: emisor } };
    (tipo === "FACTURACION" ? guardarNuevaResolucion(body) : guardarNuevaResolucionSoporte(body)).then(() => handleConsultarResoluciones(emisor));
    this.setState({ resoluciones: resolucionesFiltradas }, () => this.guardarResolucionesPortal());
  };

  filtrar = (resoluciones, { dsResolucionDian, dsPrefijo, nmInicio, nmFin }) =>
    resoluciones.filter((resolucionArray) => {
      return (
        resolucionArray.dsResolucionDian !== dsResolucionDian ||
        resolucionArray.dsPrefijo !== dsPrefijo ||
        resolucionArray.nmInicio !== nmInicio ||
        resolucionArray.nmFin !== nmFin
      );
    });

  handleOpenModal = (msgModal) => this.setState({ modalOpen: true, msgModal });

  handleCloseModal = () => this.setState({ modalOpen: false });

  render() {
    const { resoluciones } = this.props;
    const { modalOpen, msgModal } = this.state;
    return (
      <div>
        <Dialog open={modalOpen} fullWidth>
          <DialogTitle style={{ textAlign: "center" }}>
            <b>PortalFactura</b>
          </DialogTitle>
          <DialogContent>{msgModal}</DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="light" variant="contained">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Button onClick={this.guardarResoluciones} color="primary" variant="contained">
          Configurar Resoluciones
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>Resolución</TableCell>
              <TableCell style={{ textAlign: "center" }}>Prefijo</TableCell>
              <TableCell style={{ textAlign: "center" }}>Rango inicio</TableCell>
              <TableCell style={{ textAlign: "center" }}>Rango fin</TableCell>
              <TableCell style={{ textAlign: "center" }}>Fecha vencimiento</TableCell>
              <TableCell style={{ textAlign: "center" }}>Tipo de resolución</TableCell>
              <TableCell style={{ textAlign: "center" }}>Estado</TableCell>
              <TableCell style={{ textAlign: "center" }}>Principal</TableCell>
              <TableCell style={{ textAlign: "center" }}>Consecutivo automático</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(resoluciones) ? (
              resoluciones.map((resolucion, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: "center" }}>{resolucion.dsResolucionDian}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{resolucion.dsPrefijo}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{resolucion.nmInicio}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{resolucion.nmFin}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{getDateFromArray(resolucion.feVencimiento).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{resolucion.tipo}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {resolucion.cdEstado === "I" ? (
                      <Button disabled={getDateFromArray(resolucion.feVencimiento) < moment()} onClick={() => this.actualizarEstado(resolucion)}>
                        <VisibilityOff style={{ color: "#e74c3c" }} />
                      </Button>
                    ) : (
                      <Button disabled={resolucion.snPrincipal === "S"} onClick={() => this.actualizarEstado(resolucion)}>
                        <Visibility style={{ color: "#2ecc71" }} />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {resolucion.snPrincipal === "N" && (
                      <Button disabled={resolucion.cdEstado !== "A"} onClick={() => this.actualizarResolucionPrincipal(resolucion)}>
                        <ClearRounded style={{ color: "#e74c3c" }} />
                      </Button>
                    )}
                    {resolucion.snPrincipal === "S" && (
                      <Button disabled={true} style={{ color: "#000" }}>
                        <CheckRounded style={{ color: "#2ecc71" }} />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Button onClick={() => this.actualizarConsecutivoAutomatico(resolucion)}>
                      {resolucion.snConsecutivoAutomatico === "N" ? (
                        <ClearRounded style={{ color: "#e74c3c" }} />
                      ) : (
                        <CheckRounded style={{ color: "#2ecc71" }} />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <div className="form-row col-md-12 d-flex">
                <div className="form-group col-md-12 col-xl-2">
                  <div style={{ textAlign: "center" }} className="spinner-border text-primary" />
                </div>
              </div>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResolcionTable);
