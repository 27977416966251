import * as React from 'react';
import Select from 'components/molecules/Select';
import TipoIdentificacion from 'static/enums/tiposIdentificacion';
const { useMemo } = React;

function SelectTipoIdentificacion({ label = 'Tipo identificación', tipoPersona, ...rest }) {
  const options = useMemo(() => {
    const mapToOptions = (tipo) => ({
      label: tipo.nombre,
      value: tipo.tipoIdentificacionAdquiriente,
    });
    if (tipoPersona === 'NATURAL')
      return TipoIdentificacion.filter(({ forNatural }) => forNatural).map(mapToOptions);
    if (tipoPersona === 'JURIDICA')
      return TipoIdentificacion.filter(({ forNatural }) => !forNatural).map(mapToOptions);
    return TipoIdentificacion.map(mapToOptions);
  }, [tipoPersona]);

  return <Select label={label} options={options} {...rest} />;
}

export default SelectTipoIdentificacion;
